import { SearchPhotos_searchPhotos } from '__generated__/queries-photo';
import { Metadata, MetadataAsArray } from 'utils/getMetadata';
import { EXPORT_TYPE } from '../utils';
import { CheckBoxEdit } from './CheckBoxEdit';
import { ChipsEdit } from './ChipsEdit';
import { TextFieldEdit } from './TextFieldEdit';
import { getSize, InfosConfigType } from './utils';

export enum FieldType {
  TEXT = 'TEXT',
  CLICKABLE_TEXT = 'CLICKABLE_TEXT',
  CHIPS = 'CHIPS',
}

export const infosConfig: InfosConfigType[] = [
  {
    metadata: Metadata.Undersubject,
    title: 'Sous-sujet',
    fieldType: FieldType.TEXT,
    editRenderer: (props) => <TextFieldEdit {...props} />,
  },
  {
    title: 'Dimensions en 300 DPI',
    fieldType: FieldType.TEXT,
    mapToText: async (photo: SearchPhotos_searchPhotos) => {
      const { height, width, filesize } = photo;
      if (!height || !width) return undefined;
      const pxToCm = (px: number) => Math.floor((px * 2.54) / 300);
      const heightInCm = pxToCm(height);
      const widthInCm = pxToCm(width);
      return `${widthInCm} x ${heightInCm} cm / ${getSize(filesize ?? 0)}`;
    },
  },
  {
    metadata: Metadata.Credit,
    title: 'Crédit',
    fieldType: FieldType.TEXT,
    editRenderer: (props) => <TextFieldEdit {...props} multiline />,
  },
  {
    metadata: MetadataAsArray.Creators,
    title: 'Créateur',
    fieldType: FieldType.TEXT,
  },
  {
    metadata: Metadata.rights,
    title: 'Copyright',
    fieldType: FieldType.TEXT,
  },
  {
    metadata: Metadata.Source,
    title: 'Source',
    fieldType: FieldType.TEXT,
  },
  {
    metadata: Metadata.description,
    title: 'Légende',
    fieldType: FieldType.TEXT,
    editRenderer: (props) => <TextFieldEdit {...props} multiline />,
  },
  {
    metadata: MetadataAsArray.ProcessHistory,
    title: 'Historique',
    fieldType: FieldType.CHIPS,
  },
  {
    metadata: Metadata.ExportTypeWeb,
    title: 'Affectation web',
    fieldType: FieldType.TEXT,
    editRenderer: (props) => (
      <CheckBoxEdit {...props} checkedValue={EXPORT_TYPE.WEB} />
    ),
  },
  {
    metadata: Metadata.ImageNotes,
    title: 'Notes',
    fieldType: FieldType.TEXT,
    editRenderer: (props) => <TextFieldEdit {...props} multiline />,
  },
  {
    metadata: Metadata.IdPhotoPrisma,
    title: 'Nom du fichier',
    fieldType: FieldType.TEXT,
  },
  {
    metadata: Metadata.UploadedBy,
    title: 'Importé par',
    fieldType: FieldType.TEXT,
  },
  {
    metadata: Metadata.DeliveryCompany,
    title: 'Livré par',
    fieldType: FieldType.TEXT,
  },
  {
    metadata: MetadataAsArray.BackupName,
    title: "Nom du fichier d'origine",
    fieldType: FieldType.CHIPS,
  },
  {
    metadata: Metadata.DeliveryPhotoshopCredit,
    title: "Crédit d'origine",
    fieldType: FieldType.TEXT,
  },
  {
    metadata: MetadataAsArray.Keywords,
    title: 'Mots-clés',
    fieldType: FieldType.CHIPS,
    editRenderer: (props) => <ChipsEdit {...props} />,
  },
  {
    title: 'Id',
    fieldType: FieldType.TEXT,
    mapToText: async (photo: SearchPhotos_searchPhotos) => photo.id,
  },
];
