import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { statusColors } from '../../../../utils/rawArticleStatus';

const colorStyles = Object.keys(statusColors).reduce((res, color) => {
  res[`&.${color}`] = {
    '&:hover': {
      backgroundColor: `${statusColors[color]}`,
      transition: 'background-color 250ms linear;',
    },
    '&:hover *': {
      color: 'white',
    },
  };
  res[`&.${color}Selected`] = {
    backgroundColor: `${statusColors[color]}`,
  };
  res[`&.${color}Selected *`] = {
    color: 'white',
  };
  return res;
}, {} as any);

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    height: 32,
    width: 32,
    marginLeft: 12,
  },
  rootList: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    borderLeft: `1px solid ${theme.palette.grey[200]}`,
    borderRight: `1px solid ${theme.palette.grey[200]}`,
    marginBottom: 20,
    marginTop: 20,
    width: '100%',
    maxHeight: 335,
    overflowY: 'scroll',
  },
  rootListItem: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  listItem: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    cursor: 'pointer',
    ...colorStyles,
  },
  secondary: {
    position: 'absolute',
    right: 10,
  },
}));
