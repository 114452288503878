import { useApolloClient } from '@apollo/client';
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip as MaterialTooltip,
  Typography,
} from '@mui/material';
import { Lock } from '@mui/icons-material';
import {
  useNotification,
  NotificationTypeEnum,
} from '@prismamedia/one-components';
import React from 'react';
import {
  GetUnfilteredSubjectListByIssue,
  MediaSearchStatus,
  RawArticleStatus,
  TemplateType,
} from '../../../../__generated__/queries-topic';
import { AssignmentPageNumber } from '../../../../components/Assignment/AssignmentPageNumber';
import { AssignmentTitle } from '../../../../components/AssignmentTitle';
import { CountAvatar } from '../../../../components/CountAvatar';
import { ArticleLink } from '../../../../components/Link/ArticleLink';
import { MediaLink } from '../../../../components/Link/MediaLink';
import { TopicLink } from '../../../../components/Link/TopicLink';
import { Tooltip } from '../../../../components/Tooltip';
import { paths } from '../../../../routing';
import { clipboard } from '../../../../utils/clipboard';
import { gaSendEvent } from '../../../../utils/googleAnalytics';
import { useStyles } from './styles';

interface IssuesListProps {
  showEmpty: boolean;
  forPrint: boolean;
  selectedTexts: RawArticleStatus[];
  selectedMediaSearchStatuses: MediaSearchStatus[];
  issueData: GetUnfilteredSubjectListByIssue | undefined;
}

export const IssuesList: React.FC<IssuesListProps> = ({
  issueData,
  showEmpty,
  forPrint,
  selectedTexts,
  selectedMediaSearchStatuses,
}) => {
  const classes = useStyles();
  const client = useApolloClient();
  const { pushNotification } = useNotification();

  if (!issueData?.printIssue) {
    return (
      <div className={classes.container}>
        <Typography variant="h4" color="inherit">
          Aucune parution sélectionnée.
        </Typography>
        <Typography variant="caption" className={classes.noContentText}>
          Veuillez sélectionner un support et une parution à l'aide des menus
          déroulants ci-dessus pour accéder aux sujets.
        </Typography>
      </div>
    );
  }

  let counter = 0;
  return (
    <div className={classes.root}>
      <Table className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {!forPrint && <TableCell size="small" />}
            <TableCell size="small">Têtière</TableCell>
            <TableCell size="small">Intitulé du sujet</TableCell>
            <TableCell size="small" align="center">
              Nombre de page
            </TableCell>
            <TableCell size="small">Commentaires</TableCell>
            {!forPrint && (
              <>
                <TableCell size="small" className={classes.tableCellSmall}>
                  Réutilisation
                </TableCell>
                <TableCell padding="none" className={classes.tableCellSmall}>
                  Texte
                </TableCell>
                <TableCell padding="none" className={classes.tableCellSmall}>
                  Photo&nbsp;&nbsp;
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {issueData.printIssue.printHeadings
            .filter(
              (printHeading) =>
                showEmpty || printHeading.assignments.length > 0,
            )
            .map((printHeading) =>
              printHeading.assignments.length === 0 ? (
                <TableRow
                  key={printHeading.id}
                  className={
                    counter++ % 2 ? classes.greenRow : classes.whiteRow
                  }
                >
                  {!forPrint && <TableCell size="small" />}
                  <TableCell size="small">{printHeading.title}</TableCell>
                  {!forPrint && <TableCell size="small" />}
                  <TableCell
                    padding="none"
                    className={classes.tableCellSmall}
                  />
                  <TableCell
                    padding="none"
                    className={classes.tableCellSmall}
                  />
                  <TableCell
                    padding="none"
                    className={classes.tableCellSmall}
                  />
                  <TableCell size="small" className={classes.tableCellSmall} />
                  <TableCell
                    padding="none"
                    className={classes.tableCellSmall}
                  />
                </TableRow>
              ) : (
                printHeading.assignments
                  .filter((assignment) => {
                    const textStatusCondition =
                      selectedTexts.length === 0 ||
                      (assignment.rawArticle &&
                        selectedTexts.includes(
                          assignment.rawArticle.versions[0]?.status,
                        ));

                    const mediaSearchStatusCondition =
                      selectedMediaSearchStatuses.length === 0 ||
                      selectedMediaSearchStatuses.includes(
                        assignment.mediaSearchStatus,
                      );
                    return textStatusCondition && mediaSearchStatusCondition;
                  })
                  .map((assignment, idx, filteredAssignments) => {
                    const { subject } = assignment;
                    const assignmentCount = subject.assignments.length;
                    const isMultiSubject =
                      Boolean(printHeading.autoPrototype) &&
                      printHeading?.printTemplates[0]?.printTemplate.type ===
                        TemplateType.MULTIPLE_ARTICLE_TEMPLATE;
                    return (
                      <TableRow
                        key={`${printHeading.id}-${assignment.id}-${subject.id}`}
                        className={
                          idx === filteredAssignments.length - 1
                            ? counter++ % 2
                              ? classes.greenRow
                              : classes.whiteRow
                            : counter % 2
                            ? classes.greenRow
                            : classes.whiteRow
                        }
                      >
                        {!forPrint && <TableCell size="small" />}
                        {idx === 0 ? (
                          <TableCell
                            size="small"
                            className={classes.linkPointer}
                            onClick={() => {
                              clipboard(printHeading.title);
                              pushNotification({
                                message: `la têtière ${printHeading.title} à bien été copiée dans le presse-papier`,
                                type: NotificationTypeEnum.success,
                              });
                            }}
                          >
                            <MaterialTooltip
                              title="Copier dans le presse-papier"
                              placement="top"
                              classes={{ tooltip: classes.tooltipCss }}
                            >
                              <p className={classes.tooltipCss}>
                                {printHeading.title}
                              </p>
                            </MaterialTooltip>
                          </TableCell>
                        ) : (
                          <TableCell />
                        )}
                        <TableCell size="small">
                          <TopicLink
                            to={paths.SUBJECT_EDIT}
                            params={{ id: subject.id }}
                            linkClass={classes.subjectLink}
                          >
                            {subject.title}
                          </TopicLink>
                          {subject.isClassified && (
                            <Lock
                              style={{ verticalAlign: 'middle' }}
                              fontSize="small"
                            />
                          )}
                        </TableCell>
                        <TableCell size="small" align="center">
                          <AssignmentPageNumber
                            value={assignment.pageNumber}
                            assignmentId={assignment.id}
                            client={client}
                          />
                        </TableCell>
                        {
                          <TableCell size="small">
                            <MaterialTooltip title={subject.comment || ''}>
                              <p className={classes.comment}>
                                {subject.comment}
                              </p>
                            </MaterialTooltip>
                          </TableCell>
                        }
                        {!forPrint && (
                          <>
                            <TableCell
                              size="small"
                              className={classes.alignCenter}
                            >
                              {assignmentCount > 1 && (
                                <Tooltip
                                  TooltipedElement={() => (
                                    <CountAvatar
                                      count={assignmentCount - 1}
                                      withHoverStyle
                                    />
                                  )}
                                  anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'left',
                                  }}
                                  transformOrigin={{
                                    vertical: 'center',
                                    horizontal: 'right',
                                  }}
                                  TooltipContent={() => (
                                    <List dense>
                                      {subject.assignments
                                        .filter(
                                          (otherAssignment) =>
                                            otherAssignment.assignment!.id !==
                                            assignment.id,
                                        )
                                        .map((otherAssignment) => (
                                          <ListItem
                                            key={otherAssignment.assignment!.id}
                                          >
                                            <ListItemText
                                              primary={
                                                <AssignmentTitle
                                                  assignment={otherAssignment}
                                                />
                                              }
                                            />
                                          </ListItem>
                                        ))}
                                    </List>
                                  )}
                                />
                              )}
                            </TableCell>
                            <TableCell
                              padding="none"
                              className={classes.tableCellSmall}
                            >
                              <IconButton
                                aria-label="Aller au texte"
                                disabled={
                                  !!assignment.articleTemplate &&
                                  !isMultiSubject
                                }
                                size="large"
                              >
                                <ArticleLink
                                  subjectId={subject.id}
                                  assignment={{
                                    isMultiSubject:
                                      !!assignment.articleTemplate ||
                                      isMultiSubject,
                                    printTemplateId:
                                      assignment.printTemplate?.id,
                                    printHeadingId: printHeading.id,
                                    autoPrototype:
                                      printHeading.autoPrototype ||
                                      Boolean(assignment.printTemplate?.id),
                                    id: assignment && assignment.id,
                                    rawArticle: assignment.rawArticle,
                                    website: assignment.website,
                                  }}
                                />
                              </IconButton>
                            </TableCell>
                            <TableCell
                              padding="none"
                              className={classes.tableCellSmall}
                            >
                              <MediaLink
                                subjectId={subject.id}
                                mediaSearchStatus={assignment.mediaSearchStatus}
                                onClick={() =>
                                  gaSendEvent('Issue', 'click', 'photo access')
                                }
                              />
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    );
                  })
              ),
            )}
        </TableBody>
      </Table>
    </div>
  );
};
