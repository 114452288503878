import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import React, { FC } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import {
  LocationPage_GetPrintHeadingByPrintIssue_printHeadings,
  LocationPage_UpdatePrintHeadingOrderVariables,
} from '../../../__generated__/queries-topic';
import { usePrintHeadingOrderUpdater } from './updatePrintHeadingOrder.topic.graphql';

export interface SortableListProps {
  items: LocationPage_GetPrintHeadingByPrintIssue_printHeadings[];
  render: FC<LocationPage_GetPrintHeadingByPrintIssue_printHeadings>;
  printIssueId: string;
}

const reorder = (
  list: LocationPage_GetPrintHeadingByPrintIssue_printHeadings[],
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | null,
) => ({
  // styles we need to apply on draggables
  ...draggableStyle,
});

export const SortableList: FC<SortableListProps> = ({
  render,
  printIssueId,
  items,
}) => {
  const { pushNotification } = useNotification();
  const [updatePrintHeadingOrder] = usePrintHeadingOrderUpdater({
    onCompleted: () => {
      pushNotification({
        message: 'Ordre sauvegardé',
        type: NotificationTypeEnum.success,
      });
    },
    refetchQueries: ['LocationPage_GetPrintHeadingByPrintIssue'],
  });

  return (
    <DragDropContext
      data-testId="drop-test"
      onDragEnd={(result: DropResult) => {
        // dropped outside the list
        if (!result.destination) {
          return;
        }

        const newItems = reorder(
          items,
          result.source.index,
          result.destination.index,
        );

        pushNotification({
          message: 'Sauvegarde en cours',
          type: NotificationTypeEnum.info,
        });
        const data: LocationPage_UpdatePrintHeadingOrderVariables = {
          id: printIssueId,
          data: {
            printHeadings: {
              update: newItems.map((item, idx) => ({
                data: { order: idx },
                where: { title: item.title },
              })),
            },
          },
        };
        updatePrintHeadingOrder({ variables: data });
      }}
    >
      <Droppable droppableId="droppable">
        {(droppableProvided) => (
          <div ref={droppableProvided.innerRef}>
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(draggableProvided, draggableSnapshot) => (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    style={
                      getItemStyle(
                        draggableSnapshot.isDragging,
                        draggableProvided.draggableProps.style!,
                      ) as React.CSSProperties
                    }
                  >
                    {render(item)}
                  </div>
                )}
              </Draggable>
            ))}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
