import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import {
  notificationQueue,
  NotificationTypeEnum,
  useDialog,
  useNotification,
} from '@prismamedia/one-components';
import {
  useCreatePhoto,
  useUpdatePhotoCache,
  useUpdatePhotoFile,
} from 'apollo/mutations/photos.photo.graphql';
import { useGetArchives } from 'apollo/queries/archives.photo.graphql';
import { SearchPhotosPhoto } from 'apollo/queries/photos.photo.graphql';
import { FC, useState } from 'react';
import { getImageBlob } from 'utils/downloadImage';
import { getMetadataAsArray, MetadataAsArray } from 'utils/getMetadata';
import { ArchiveOrderByInput, ArchiveType } from '__generated__/queries-photo';
import { CategorySelect } from '../CategorySelect';
import { PhotoListDialog } from '../PhotoListDialog';
import { useStyles } from './styles';

enum AccessName {
  READ = 'READ',
  SEND_AND_DOWNLOAD = 'SEND_AND_DOWNLOAD',
  ARCHIVE_FROM = 'ARCHIVE_FROM',
  WRITE_AND_IMPORT_TO = 'WRITE_AND_IMPORT_TO',
  DELETE = 'DELETE',
}

interface ArchiveDialogProps {
  photos: SearchPhotosPhoto[];
}

export const ArchiveDialog: FC<ArchiveDialogProps> = ({ photos }) => {
  const classes = useStyles();
  const {
    data: archivesData,
    loading: archivesLoading,
    error,
  } = useGetArchives({
    first: 1000,
    orderBy: [ArchiveOrderByInput.createdAt_ASC],
  });
  const { closeDialog } = useDialog();
  const { pushNotification, removeNotification } = useNotification();
  const filteredArchives =
    archivesData?.archives.filter(
      ({ accesses, key, type }) =>
        key &&
        type &&
        [ArchiveType.NEWS, ArchiveType.ARCHIVE, ArchiveType.DELIVERY].includes(
          type,
        ) &&
        accesses?.includes(AccessName.WRITE_AND_IMPORT_TO),
    ) || [];
  const [selectedArchiveId, setSelectedArchiveId] = useState<string>('');
  const [selectedCategoryId, setSelectedCategoryId] = useState<
    string | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);
  const createPhoto = useCreatePhoto();
  const updatePhotoFile = useUpdatePhotoFile();
  const updatePhotoCache = useUpdatePhotoCache();
  const selectedArchive = archivesData?.archives.find(
    ({ id }) => id === selectedArchiveId,
  );
  const submitDisabled = !!(
    !selectedArchive ||
    (selectedArchive?.hasCategory && !selectedCategoryId)
  );

  const getPhotoFile = async (photo: SearchPhotosPhoto) => {
    const { data } = await updatePhotoFile({ photoId: photo.id });
    if (!data?.updatePhotoFile?.downloadUrl) {
      throw new Error(`No download url for photo of id: ${photo.id}`);
    }

    const blob = await getImageBlob(data.updatePhotoFile.downloadUrl);

    return new File(
      [blob],
      getMetadataAsArray(MetadataAsArray.BackupName, photo)[0] || 'Sans-titre',
      { type: photo.contentType || undefined },
    );
  };

  const archivePhoto = async () => {
    if (!selectedArchive || submitDisabled) return;

    setLoading(true);

    try {
      const queue = photos.map((photo) => {
        const result = getPhotoFile(photo).then((file) =>
          createPhoto({
            file,
            archiveConnect: { id: selectedArchiveId },
            category: selectedCategoryId
              ? { id: selectedCategoryId }
              : undefined,
          }),
        );

        return () => result;
      });

      await notificationQueue(
        queue,
        pushNotification,
        removeNotification,
        'Archivage',
      );

      await updatePhotoCache();

      closeDialog();
    } catch (e: any) {
      pushNotification({
        message: e.message,
        type: NotificationTypeEnum.error,
      });
    }

    setLoading(false);
  };

  return (
    <PhotoListDialog
      title="Archiver"
      submitText="Archiver"
      photos={photos}
      disabled={!selectedArchive || submitDisabled}
      onSubmit={archivePhoto}
      loading={loading}
    >
      {archivesLoading || error ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 100,
            textAlign: 'center',
          }}
        >
          {archivesLoading ? (
            <CircularProgress size={60} />
          ) : (
            <Typography color="error">{error?.message}</Typography>
          )}
        </Box>
      ) : (
        <>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>Sélectionner une archive</InputLabel>
            <Select
              label="Sélectionner une archive"
              value={selectedArchiveId}
              onChange={(event) => {
                setSelectedArchiveId(event.target.value as string);
              }}
            >
              {filteredArchives?.map(({ id, title }) => (
                <MenuItem key={id} value={id}>
                  {title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedArchive?.hasCategory && (
            <>
              <Typography
                color="textPrimary"
                variant="body1"
                className={classes.formSubtitle}
              >
                Sélectionnez une catégorie
              </Typography>
              <CategorySelect
                archiveId={selectedArchive.id}
                className={classes.categoryField}
                selectedCategoryId={selectedCategoryId}
                setSelectedCategoryId={setSelectedCategoryId}
              />
            </>
          )}
        </>
      )}
    </PhotoListDialog>
  );
};
