import { Chip, Typography } from '@mui/material';
import {
  ChipList,
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import { useUpdatePhoto } from 'apollo/mutations/photos.photo.graphql';
import { SearchPhotosPhoto } from 'apollo/queries/photos.photo.graphql';
import React, {
  FC,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { getMetadata, getMetadataAsArray } from 'utils/getMetadata';
import { MetadataNameEnumType } from '__generated__/queries-photo';
import { FieldsValues } from '..';
import { FieldType } from '../template';
import { InfosConfigType } from '../utils';
import { useStyles } from './styles';

interface PhotoInfoProps {
  photo: SearchPhotosPhoto;
  editableFields: MetadataNameEnumType[];
  config: InfosConfigType;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  value: FieldsValues;
  setValue: Dispatch<SetStateAction<FieldsValues>>;
}

export const PhotoInfo: FC<PhotoInfoProps> = ({
  photo,
  editableFields,
  config,
  loading,
  setLoading,
  value,
  setValue,
}) => {
  const classes = useStyles();
  const updatePhoto = useUpdatePhoto();
  const { pushNotification } = useNotification();
  const [textContent, setTextContent] = useState('');

  useEffect(() => {
    (async () => {
      let content;
      try {
        content = await config.mapToText?.(photo);
      } catch (e) {}
      setTextContent(
        content ||
          (config.metadata && getMetadata(config.metadata, photo)) ||
          '-',
      );
    })();
  }, [config, photo]);

  if (
    !editableFields ||
    !config.metadata ||
    !editableFields.includes(config.metadata) ||
    !config.editRenderer
  ) {
    return (
      <>
        <Typography variant="caption" className={classes.title}>
          {config.title}
        </Typography>
        {(() => {
          if (config.fieldType === FieldType.CHIPS) {
            if (
              config.metadata &&
              !getMetadataAsArray(config.metadata, photo).length
            ) {
              return <Typography variant="body2">-</Typography>;
            }

            return (
              <ChipList
                className={classes.chipsList}
                items={
                  (config.metadata &&
                    getMetadataAsArray(config.metadata, photo)
                      .filter((item) => item && item !== 'null')
                      .map((item, index) => ({
                        id: String(index),
                        label: item!,
                        tooltip: item!.length > 30 ? item! : '',
                      }))) ||
                  []
                }
              />
            );
          }

          if (
            textContent !== '-' &&
            config.fieldType === FieldType.CLICKABLE_TEXT
          ) {
            return (
              <Chip
                classes={{ root: classes.clickableText }}
                label={textContent}
              />
            );
          }

          return (
            <Typography className={classes.textContent} variant="body2">
              {textContent}
            </Typography>
          );
        })()}
      </>
    );
  }

  return config.editRenderer({
    value: value[config.metadata],
    title: config.title,
    variant: 'standard',
    loading,
    onChange: (val) =>
      setValue((prev) => ({
        ...prev,
        [config.metadata!]: val,
      })),
    onValidate: async (val) => {
      setLoading(true);
      const newVals = Array.isArray(val) ? val : [val];
      try {
        await updatePhoto({
          photo,
          metadatas: newVals.map((v) => ({
            metadata: config.metadata!,
            value: v!,
          })),
        });
      } catch (e: any) {
        pushNotification({
          message: e.message,
          type: NotificationTypeEnum.error,
        });
      }
      setLoading(false);
    },
    labelClass: classes.title,
  });
};
