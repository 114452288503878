import React, { Component } from 'react';
import { gaSendException } from '../../utils/googleAnalytics';

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
  info?: React.ErrorInfo;
}

export class ErrorBoundary extends Component<any, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    hasError: false,
  };

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.setState({ hasError: true, error, info });
    gaSendException(error, info);
  }

  render() {
    const { hasError, error, info } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h1>Erreur :/</h1>
          {error && (
            <div>
              The error: <br />
              <pre>{error.toString()}</pre>
            </div>
          )}
          {info && (
            <div>
              Where it occured: <br />
              <pre>{info.componentStack}</pre>
            </div>
          )}
        </div>
      );
    }
    return this.props.children;
  }
}
