import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import {
  AutocompleteItem,
  AutocompleteSelect,
  LoadingButton,
  notificationQueue,
  NotificationTypeEnum,
  useDialog,
  useNotification,
} from '@prismamedia/one-components';
import {
  useCreatePhoto,
  useUpdatePhotoCache,
  useUpdatePhotoFile,
} from 'apollo/mutations/photos.photo.graphql';
import { SearchPhotosPhoto } from 'apollo/queries/photos.photo.graphql';
import { useSubjectsGetter } from 'pages/SubjectListPage/SubjectList/getFilteredSubjectList.topic.graphql';
import { FC, useEffect, useState } from 'react';
import { getImageBlob } from 'utils/downloadImage';
import {
  getMetadataAsArray,
  Metadata,
  MetadataAsArray,
} from 'utils/getMetadata';
import { GetSubjects_subjects } from '__generated__/queries-topic';
import { UnderSubjectSelect } from '../UnderSubjectSelect';
import { useStyles } from './styles';

interface CopyPhotosToSubjectProps {
  photos: SearchPhotosPhoto[];
}

export const CopyPhotosToSubject: FC<CopyPhotosToSubjectProps> = ({
  photos,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState<
    AutocompleteItem | undefined
  >(undefined);
  const [underSubject, setUnderSubject] = useState('');
  const { closeDialog } = useDialog();
  const getSubjects = useSubjectsGetter();
  const createPhoto = useCreatePhoto();
  const updatePhotoFile = useUpdatePhotoFile();
  const { pushNotification, removeNotification } = useNotification();
  const updatePhotoCache = useUpdatePhotoCache();

  useEffect(() => {
    setUnderSubject('');
  }, [selectedSubject]);

  const fetchSubjects = (search: string) =>
    getSubjects({
      where: search ? { title_contains: search } : {},
      skip: 0,
    }).then((data) =>
      data.subjects.map((sub) => ({
        id: sub.id,
        label: sub.title,
        additionnals: { subject: sub },
      })),
    );

  const copyPhotosToSubject = async () => {
    if (!selectedSubject) return;

    setLoading(true);

    try {
      const selectedSubjectInAdd = selectedSubject.additionnals
        ?.subject as GetSubjects_subjects;

      const queue = photos.map((photo) => {
        const result = updatePhotoFile({ photoId: photo.id }).then(
          async ({ data }) => {
            if (!data?.updatePhotoFile?.downloadUrl) {
              throw new Error(`No download url for photo of id: ${photo.id}`);
            }

            const blob = await getImageBlob(data.updatePhotoFile.downloadUrl);

            const file = new File(
              [blob],
              getMetadataAsArray(MetadataAsArray.BackupName, photo)[0] ||
                'Sans-titre',
              { type: photo.contentType || undefined },
            );

            return createPhoto({
              file,
              archiveConnect: {
                archiveId: selectedSubjectInAdd.unit.photoArchiveId,
              },
              metadatas: [
                {
                  metadata: Metadata.Undersubject,
                  value: underSubject || undefined,
                },
                { metadata: Metadata.ID_subject, value: selectedSubject.id },
              ],
            });
          },
        );

        return () => result;
      });

      await notificationQueue(
        queue,
        pushNotification,
        removeNotification,
        'Copie',
      );
      await updatePhotoCache();

      closeDialog();
    } catch (e: any) {
      pushNotification({
        message: e.message,
        type: NotificationTypeEnum.error,
      });
    }

    setLoading(false);
  };

  return (
    <>
      <DialogTitle>Copier vers un autre sujet</DialogTitle>

      <DialogContent>
        <AutocompleteSelect
          className={classes.field}
          label="Sujet"
          variant="outlined"
          fetchList={fetchSubjects}
          value={selectedSubject}
          onChange={setSelectedSubject}
        />
        {selectedSubject && (
          <UnderSubjectSelect
            className={classes.field}
            subject={selectedSubject.additionnals?.subject}
            value={underSubject}
            onChange={setUnderSubject}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Annuler
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          disabled={!selectedSubject}
          loading={loading}
          onClick={copyPhotosToSubject}
        >
          Copier
        </LoadingButton>
      </DialogActions>
    </>
  );
};
