import { parse, stringify } from 'query-string';

export const addUrlFilters = (history: any, location: any) => (filters: {
  [key: string]: string | string[] | null;
}) => {
  const parsedSearch = parse(location.search) as { [key: string]: string };

  // Remove filter if null value
  Object.keys(filters)
    .filter((filterKey) => filters[filterKey] === null)
    .forEach((filterKey) => {
      delete parsedSearch[filterKey];
      delete filters[filterKey];
    });

  const newLocation = {
    ...location,
    search: stringify({ ...parsedSearch, ...filters }, { encode: false }),
  };

  history.push(newLocation);
};
