import { DialogTitle } from '@mui/material';
import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import React, { FC } from 'react';

import { useEmailSubjectSender } from '../../../apollo/mutations/emailSubjectMutation.topic.graphql';
import { EmailForm, EmailFormModel } from '../EmailForm';

interface EmailSubjectDialogProps {
  subjectId: string;
  closeDialog: () => void;
}

export const EmailSubjectDialog: FC<EmailSubjectDialogProps> = ({
  subjectId,
  closeDialog,
}) => {
  const { pushNotification } = useNotification();
  const [sendEmailSubject] = useEmailSubjectSender({
    onCompleted: () => {
      pushNotification({
        message: 'Le mail a correctement été envoyé.',
        type: NotificationTypeEnum.success,
      });
      closeDialog();
    },
    onError: () => {
      pushNotification({
        message: "Une erreur est survenue dans l'envoi du mail.",
        type: NotificationTypeEnum.error,
      });
    },
  });
  const handleSubmit = ({ message, selectedUsers }: EmailFormModel) =>
    sendEmailSubject({
      variables: {
        subject: subjectId,
        recipients: selectedUsers.map((user) => user.email!),
        comment: message,
      },
    });

  return (
    <>
      <DialogTitle>Envoyer par email</DialogTitle>
      <EmailForm onCancel={closeDialog} onSubmit={handleSubmit} />
    </>
  );
};
