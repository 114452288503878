import { convertToRaw } from 'draft-js';
import md5 from 'md5';
import { omit } from 'ramda';
import uuid from 'uuid/v4';
import { ApiBodyData, FormField } from '.';
import { auth } from '../../utils/auth';
import { Version } from '../../utils/rawArticleStatus';
import {
  ArticleForm_RawArticle_assignment,
  GetRawArticle_rawArticle,
  GetRawArticle_rawArticle_assignment_articleTemplate,
  RawArticleStatus,
  UpdateAssignment,
  UpdateAssignmentVariables,
} from '../../__generated__/queries-topic';
import { getResizedUrl } from './utils/resizer.utils';
import User from '@prismamedia/one-user';

interface EditorModel {
  id: string;
  name: string | null;
  avatarUrl: string | null;
}

export interface ArticleFormModel {
  id?: string;
  body: string;
  leftOver?: string[];
  lastVersionId?: string;
  status: RawArticleStatus;
  assignmentId?: string;
  subjectId?: string;
  subjectTitle?: string;
  textIsReadOnly: boolean;
  createdAt?: Date;
  allowedStatuses?: RawArticleStatus[];
  printTemplates?: ArticleForm_RawArticle_assignment['printTemplate'][];
  brandKey?: string;
  printTemplateId?: string;
  versions?: Version[];
  editor?: EditorModel | null;
  unitTitle?: string;
  articleTemplate?: GetRawArticle_rawArticle_assignment_articleTemplate | null;
}

export const getEmptyArticleForm = (): ArticleFormModel => ({
  id: '',
  body: JSON.stringify({}),
  status: RawArticleStatus.Editing,
  assignmentId: '',
  textIsReadOnly: false,
  createdAt: new Date(),
  printTemplates: [],
});

export const postSave = (
  currentArticle: ArticleFormModel,
  data: UpdateAssignment,
): ArticleFormModel => {
  const result = data?.updateAssignment.rawArticle;
  const lastVersion = result?.versions[result.versions.length - 1];
  const versions = result?.versions
    .filter((item) => Boolean(item.editor?.id))
    .map((item) => ({
      id: item.id,
      status: item.status,
      editorId: item.editor?.id as string,
      editor: item.editor,
    }));
  if (!lastVersion) {
    return currentArticle;
  }

  return {
    ...currentArticle,
    id: result?.id,
    editor: lastVersion.editor,
    status: lastVersion.status,
    textIsReadOnly: lastVersion.textIsReadOnly,
    allowedStatuses: lastVersion.allowedStatuses,
    createdAt: new Date(lastVersion.createdAt),
    lastVersionId: lastVersion.id,
    versions,
  };
};

export const apiToForm = (
  rawArticle: GetRawArticle_rawArticle | null,
): ArticleFormModel => {
  const emptyArticleForm = getEmptyArticleForm();
  if (!rawArticle || !rawArticle.assignment) {
    return emptyArticleForm;
  }
  const versions = rawArticle.versions
    .filter((item) => Boolean(item.editor?.id))
    .map((item) => ({
      id: item.id,
      status: item.status,
      editorId: item.editor?.id as string,
      editor: item.editor,
    }));
  const lastVersion = rawArticle.versions[rawArticle.versions.length - 1];
  if (!lastVersion) {
    return emptyArticleForm;
  }

  return {
    id: rawArticle.id,
    body: lastVersion.body || emptyArticleForm.body,
    leftOver: JSON.parse(lastVersion.leftOver || '[]'),
    lastVersionId: lastVersion.id,
    status: lastVersion.status,
    allowedStatuses: lastVersion.allowedStatuses,
    assignmentId: rawArticle.assignment.id,
    subjectId: rawArticle.assignment.subject.id,
    subjectTitle: rawArticle.assignment.subject.title,
    textIsReadOnly: lastVersion.textIsReadOnly,
    createdAt: new Date(lastVersion.createdAt),
    printTemplates: rawArticle.assignment.printHeading?.printTemplates.map(
      (item) => item.printTemplate,
    ),
    brandKey: rawArticle.assignment.subject.unit.title,
    versions,
    editor: lastVersion.editor || null,
    unitTitle: rawArticle.assignment.subject.unit.title,
    articleTemplate: rawArticle.assignment.articleTemplate,
  };
};

export const formToApi = (
  data: ArticleFormModel,
): UpdateAssignmentVariables['data']['rawArticle'] => {
  const previousVersion = data.lastVersionId
    ? {
        previousVersion: {
          connect: { id: data.lastVersionId },
        },
      }
    : {};

  const versionData = {
    id: uuid(),
    editorId: (auth.user as User).id,
    status: data.status,
    body: data.body,
    ...previousVersion,
  };

  const model = {
    versions: {
      create: [data.textIsReadOnly ? omit(['body'], versionData) : versionData],
    },
  };

  return data.id
    ? {
        update: model,
      }
    : {
        create: model,
      };
};

export const serializeFormValues = (
  printTemplateId: string,
  formValues: FormField[],
): ApiBodyData => {
  const rawArticleBody: ApiBodyData = {
    idPrintTemplate: printTemplateId,
    fields: [],
  };
  formValues.forEach((formField) => {
    switch (formField.type) {
      case 'TEXT':
      case 'DRAFT': {
        rawArticleBody.fields.push({
          id: formField.id,
          value: JSON.stringify(
            convertToRaw(formField.value.getCurrentContent()),
          ),
        });
        break;
      }
      case 'IMAGE': {
        const firstValidMedia = formField.value.find(
          (media) => media?.iframely?.meta,
        );

        if (!firstValidMedia) {
          break;
        }

        const {
          meta,
          links: { file = [] },
        } = firstValidMedia.iframely;
        const mediaUrl = meta.url || meta.canonical;
        const mediaFileLink = file.find(({ href }) => href === mediaUrl);

        const updatedMediaUrl = firstValidMedia
          ? getResizedUrl(firstValidMedia)
          : '';

        rawArticleBody.fields.push({
          id: formField.id,
          value: firstValidMedia
            ? JSON.stringify({
                path: updatedMediaUrl,
                media: firstValidMedia,
                cacheKey: md5(updatedMediaUrl),
                fileType: mediaFileLink && mediaFileLink.type,
              })
            : '',
        });
        break;
      }
      default:
        break;
    }
  });
  return rawArticleBody;
};
