import { useApolloClient } from '@apollo/client';
import MoreVert from '@mui/icons-material/MoreVert';
import { IconButton, MenuItem, Paper, Typography } from '@mui/material';
import {
  NotificationTypeEnum,
  useDialog,
  useMenu,
  useNotification,
} from '@prismamedia/one-components';
import { FetchResult } from 'apollo-link';
import { useGetPhotosCount } from 'apollo/queries/photos.photo.graphql';
import arrayMutators from 'final-form-arrays';
import { FC } from 'react';
import { Field, Form } from 'react-final-form';
import { useHistory, useLocation } from 'react-router-dom';
import { MetadataNameEnumType } from '__generated__/queries-photo';
import { AppToolbar } from '../../../components/AppToolbar';
import { SaveButton } from '../../../components/Button/SaveButton';
import { EmailSubjectDialog } from '../../../components/EmailDialog/EmailSubjectDialog';
import { TextField } from '../../../components/FinalFormMaterial/TextField';
import { MediaLink } from '../../../components/Link/MediaLink';
import { TopicNavigationPrompt } from '../../../components/NavigationPrompt';
import { PrintPortal } from '../../../components/PrintPortal';
import { ShareSubjectDialog } from '../../../components/ShareDialog/ShareSubjectDialog';
import { GET_SUBJECT_USERS } from '../../../components/ShareDialog/ShareSubjectDialog/getSubjectUsers.topic.graphql';
import { SHARE_SUBJECT_MUTATION } from '../../../components/ShareDialog/ShareSubjectDialog/shareSubjectMutation.topic.graphql';
import { WithDrawers } from '../../../components/WithDrawers';
import { paths, replaceParams } from '../../../routing';
import { auth } from '../../../utils/auth';
import { handleApolloError } from '../../../utils/error';
import { gaSendEvent } from '../../../utils/googleAnalytics';
import {
  GetSubjectUsers,
  GetSubject_subject_attachments,
  GetSubject_subject_subjectUsers,
  ShareSubject,
  ShareSubjectVariables,
  UpsertSubject,
} from '../../../__generated__/queries-topic';
import { Assignments } from '../Assignments';
import { Attachments } from '../Attachments';
import { Confidentiality } from '../Confidentiality';
import { SubjectTemplate } from '../PrintTemplate';
import { SubjectCategory } from '../SubjectCategory';
import { formToApi, SubjectFormModel } from './dataTransformer';
import { useStyles } from './styles';
import { useSubjectUpsert } from './upsertSubjectMutation.topic.graphql';

interface SubjectFormProps {
  initialValues: SubjectFormModel;
  refetchSubject?: () => void;
  titleDisabled: boolean;
  confidentialUsersList?: GetSubject_subject_subjectUsers[];
  isClassified: boolean;
  attachments?: GetSubject_subject_attachments[];
}

export const SubjectForm: FC<SubjectFormProps> = ({
  initialValues: initialValuesFromApi,
  refetchSubject,
  titleDisabled,
  confidentialUsersList = [],
  isClassified,
  attachments,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { pushNotification, removeNotification } = useNotification();
  const { openMenu, closeMenu } = useMenu();
  const { openDialog, closeDialog } = useDialog();
  const client = useApolloClient();
  const { data: photosCountData } = useGetPhotosCount({
    where: {
      isUploaded: true,
      photoMetadatas_some: {
        value: initialValuesFromApi.id,
        metadata: { name: MetadataNameEnumType.ID_subject },
      },
    },
  });
  const [upsertSubject] = useSubjectUpsert({
    onError: (error) => {
      const message = handleApolloError(
        error,
        [
          {
            code: 'CONFLICT',
            message: 'Le titre du sujet existe déjà.',
          },
        ],
        'Erreur lors de la sauvegarde du sujet.',
      );
      pushNotification({
        message,
        type: NotificationTypeEnum.error,
      });
    },
    onCompleted: async (result) => {
      if (result.upsertSubject?.id) {
        pushNotification({
          message: 'Le sujet a bien été sauvegardé',
          type: NotificationTypeEnum.success,
        });
        if (refetchSubject) {
          refetchSubject();
        } else {
          history.push({
            pathname: replaceParams(paths.SUBJECT_EDIT, {
              id: result.upsertSubject.id,
            }),
            search: location.search,
          });
        }
      }
    },
  });

  const validateForm = (values: SubjectFormModel) => {
    const errors: {
      title?: string;
      subjectCategory?: string;
      subSubjectCategory?: string;
    } = {};
    if (!values.title) {
      errors.title = 'Titre requis';
    } else {
      if (values.title.trim().split(' ').length < 2) {
        errors.title =
          'Le titre du sujet doit comporter au minimum 2 mots et il ne doit pas faire plus de 700 caractères.';
      }
      if (values.title.length > 700) {
        errors.title = 'Le titre ne doit pas faire plus de 700 caractères';
      }
      const titleRegex = /^[A-Za-z0-9áàâäãåéèêëíìîïñóòôöõúùûüÂÊÁËÈÍÎÏÌÓÔŒœ'()+,-.! ]+$/;
      if (!titleRegex.test(values.title)) {
        errors.title = 'Le titre contient des caractères non autorisés';
      }
    }

    return errors;
  };

  return (
    <Form
      mutators={{ ...arrayMutators }}
      initialValues={initialValuesFromApi}
      validate={validateForm as any}
      onSubmit={
        (async (values: SubjectFormModel) => {
          const authUserId = auth.user?.id || '';
          const variables = formToApi(values);
          const result = (await upsertSubject({
            variables,
          })) as FetchResult<UpsertSubject>;

          if (!isClassified && values.isClassified && result && result.data) {
            if (
              confidentialUsersList.some(({ userId }) => userId === authUserId)
            ) {
              return;
            }
            await client.mutate<ShareSubject, ShareSubjectVariables>({
              mutation: SHARE_SUBJECT_MUTATION,
              variables: {
                subject: result.data.upsertSubject?.id || '',
                userIds: [authUserId],
              },
            });

            const getSubjectUsersQuery: GetSubjectUsers | null = client.readQuery(
              {
                query: GET_SUBJECT_USERS,
                variables: {
                  id: result.data.upsertSubject?.id,
                },
              },
            );

            if (!getSubjectUsersQuery || !getSubjectUsersQuery.subject) {
              return;
            }

            return client.writeQuery({
              query: GET_SUBJECT_USERS,
              variables: {
                id: result.data.upsertSubject?.id,
              },
              data: {
                ...getSubjectUsersQuery,
                subject: {
                  ...getSubjectUsersQuery.subject,
                  subjectUsers: [
                    ...getSubjectUsersQuery.subject.subjectUsers,
                    {
                      userId: auth.user?.id,
                      __typename: 'SubjectUser',
                      user: {
                        __typename: 'User',
                        email: auth.user?.email,
                        avatarUrl: auth.user?.avatarUrl || '',
                        name: auth.user?.name,
                      },
                    },
                  ],
                },
              },
            });
          }
        }) as any
      }
      render={({
        form,
        submitSucceeded,
        dirtySinceLastSubmit,
        dirty,
        submitting,
        initialValues,
        values,
      }: any) => {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              form.submit();
            }}
          >
            <AppToolbar
              title={initialValues.title || 'Nouveau Sujet'}
              subtitle={
                initialValues.creatorName &&
                `Créé par ${initialValues.creatorName}`
              }
              previousRoute={{ pathname: paths.SUBJECT_LIST }}
              actionButton={
                <>
                  <Confidentiality
                    subjectId={initialValues.id}
                    confidentialUsersList={confidentialUsersList}
                    isClassified={isClassified}
                  />
                  <SaveButton onClick={form.submit} />
                  <IconButton
                    onClick={(e) =>
                      openMenu(
                        e.currentTarget,
                        <div>
                          <MenuItem
                            onClick={() => {
                              openDialog(
                                <EmailSubjectDialog
                                  subjectId={initialValues.id}
                                  closeDialog={closeDialog}
                                />,
                              );
                              closeMenu();
                            }}
                          >
                            Envoyer le lien par email
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              openDialog(
                                <ShareSubjectDialog
                                  subjectId={initialValues.id}
                                  closeDialog={closeDialog}
                                />,
                              );
                              closeMenu();
                            }}
                          >
                            Partager le sujet
                          </MenuItem>
                        </div>,
                      )
                    }
                  >
                    <MoreVert />
                  </IconButton>
                </>
              }
            />
            <TopicNavigationPrompt
              when={
                (!(submitSucceeded || submitting) && dirty) ||
                (submitSucceeded && dirtySinceLastSubmit)
              }
              title="Êtes-vous sur de vouloir quitter la page ?"
              content="Vous avez des modifications non sauvegardées"
              confirmLabel="Oui"
              cancelLabel="Non"
            />
            <div className={classes.main}>
              <WithDrawers
                drawerRightComponent={
                  <div className={classes.sidebar}>
                    <div className={classes.subjectCategory}>
                      <SubjectCategory
                        subjectCategory={values.subjectCategory}
                        unitId={values.unit}
                      />
                    </div>
                  </div>
                }
                drawerRightSize={'400'}
                closable={true}
              >
                <div className={classes.mainForm}>
                  <Paper className={classes.otherFields} elevation={0}>
                    <Field
                      component={TextField}
                      disabled={titleDisabled}
                      title={
                        titleDisabled
                          ? 'L\'intitulé du sujet qui a au moins une version d\'un article au statut "A maquetter" ne peut être modifié'
                          : ''
                      }
                      label="Intitulé du sujet"
                      name="title"
                    />
                  </Paper>
                  <Paper className={classes.photoBox} elevation={0}>
                    <Typography variant="h6" color="textSecondary">
                      <strong>Sélection photos</strong>
                    </Typography>
                    <MediaLink
                      subjectId={values.id}
                      photosCount={photosCountData?.photoCount}
                      onClick={() =>
                        gaSendEvent('Edit subject', 'click', 'photo access')
                      }
                    />
                  </Paper>
                  <Assignments
                    subjectId={values.id}
                    unitId={values.unit}
                    assignments={values.assignments}
                    refetchSubject={refetchSubject}
                    form={form}
                    values={values}
                    formErrors={validateForm(values as SubjectFormModel)}
                  />
                  <Paper className={classes.otherFields} elevation={0}>
                    <Field
                      component={TextField}
                      multiline
                      label="Synopsis"
                      name="synopsis"
                      autoFocus
                    />
                    <Field
                      component={TextField}
                      multiline
                      label="Demande de photos"
                      name="mediaRequest"
                    />
                    <Field
                      component={TextField}
                      multiline
                      label="Commentaires"
                      name="comment"
                    />
                  </Paper>
                  {initialValues.id && (
                    <Attachments
                      subjectId={initialValuesFromApi.id}
                      attachmentsList={attachments}
                      pushNotification={pushNotification}
                      removeNotification={removeNotification}
                    />
                  )}
                </div>
              </WithDrawers>
              <PrintPortal>
                <SubjectTemplate
                  idSubject={values.id}
                  creatorName={initialValues.creatorName}
                />
              </PrintPortal>
            </div>
          </form>
        );
      }}
    />
  );
};
