import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import {
  LoadingButton,
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import { append, update } from 'ramda';
import React, { FC, useCallback, useState } from 'react';
import { Field, FieldRenderProps, Form } from 'react-final-form';
import uuid from 'uuid/v4';
import {
  LocationPage_GetPrintPublicationsByUnit,
  LocationPage_UpsertPrintPublicationVariables,
  LocationPage_UpsertPrintPublication_upsertPrintPublication,
} from '../../../../__generated__/queries-topic';
import { Select } from '../../../../components/FinalFormMaterial/Select';
import { TextField } from '../../../../components/FinalFormMaterial/TextField';
import { mapPath } from '../../../../utils/mapPath';
import { GET_PRINT_PUBLICATIONS_BY_UNIT } from '../getPrintPublicationsByUnit.topic.graphql';
import { usePrintPublicationUpsert } from './upsertPrintPublication.topic.graphql';

interface Brand {
  id: string;
  title: string;
}

interface PrintPublicationDialogFormProps {
  open: boolean;
  printPublication?: LocationPage_UpsertPrintPublication_upsertPrintPublication;
  brands: Brand[];
  handleClose: () => void;
  unitId: string;
}

interface PrintPublicationFormModel {
  title: string;
  brand: string;
  key: string;
}

export const PrintPublicationDialogForm: FC<PrintPublicationDialogFormProps> = ({
  unitId,
  open,
  printPublication,
  brands,
  handleClose,
}) => {
  const { pushNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [upsertPrintPublication] = usePrintPublicationUpsert({
    update: (cache, { data }) => {
      if (!data?.upsertPrintPublication) {
        return;
      }
      const result = cache.readQuery<LocationPage_GetPrintPublicationsByUnit>({
        query: GET_PRINT_PUBLICATIONS_BY_UNIT,
        variables: { unitId },
      });
      if (result && result.unit) {
        const newPrintPublication = data.upsertPrintPublication;
        const brandId = newPrintPublication.brand?.id;
        const brandIdx = result.unit.brands.findIndex(
          (brand) => brand.id === brandId,
        );

        const newData = mapPath(
          ['unit', 'brands', brandIdx, 'printPublications'],
          (printPublications: any) => {
            const idx = printPublications.findIndex(
              (
                pp: LocationPage_UpsertPrintPublication_upsertPrintPublication,
              ) => pp.id === newPrintPublication.id,
            );
            if (idx !== -1) {
              return update(idx, newPrintPublication, printPublications);
            }

            return append(newPrintPublication, printPublications);
          },
          result,
        );

        cache.writeQuery({
          query: GET_PRINT_PUBLICATIONS_BY_UNIT,
          variables: { unitId },
          data: { unit: newData.unit },
        });
      }
    },
    onCompleted: (data) => {
      handleClose();
      const support =
        data &&
        data.upsertPrintPublication &&
        data.upsertPrintPublication.title;
      pushNotification({
        message: `Le support ${support} a bien été enregistré.`,
        type: NotificationTypeEnum.success,
      });
    },
    onError: (error) => {
      console.error(error);
      pushNotification({
        message: "Erreur lors de l'enregistrement du support",
        type: NotificationTypeEnum.error,
      });
    },
  });

  const handleSubmitPrintPublication = useCallback(
    async (values: PrintPublicationFormModel) => {
      setIsLoading(true);
      const id = (printPublication && printPublication.id) || uuid();
      const updateData = {
        title: values.title,
        key: values.key,
        brand: {
          connect: { id: values.brand },
        },
      };
      const createData = {
        ...updateData,
        id,
      };
      const mutationData: LocationPage_UpsertPrintPublicationVariables = {
        where: { id },
        create: createData,
        update: updateData,
      };
      await upsertPrintPublication({ variables: mutationData });
      setIsLoading(false);
    },
    [printPublication, upsertPrintPublication],
  );

  const formInitialValues = {
    title: printPublication ? printPublication.title : '',
    brand: printPublication ? printPublication.brand?.id : '',
    key: printPublication ? printPublication.key : '',
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Form
          initialValues={formInitialValues}
          validate={
            ((values: PrintPublicationFormModel) => {
              const errors: {
                title?: string;
                brand?: string;
                key?: string;
              } = {};
              if (!values.title) {
                errors.title = 'Titre obligatoire';
              }
              if (!values.brand) {
                errors.brand = 'Marque obligatoire';
              }
              if (!values.key) {
                errors.key = '"Trigramme" obligatoire';
              }
              return errors;
            }) as any
          }
          onSubmit={handleSubmitPrintPublication as any}
          render={({ form }) => {
            const title = printPublication
              ? 'Éditer le support'
              : 'Créer un support';
            return (
              <>
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                  <Field
                    data-cy="create-publication-brand-select"
                    component={Select as FC<FieldRenderProps>}
                    name="brand"
                    label="Marque"
                    fullWidth
                  >
                    {brands.map((brand) => (
                      <MenuItem
                        data-cy="create-publication-brand-select-choice"
                        key={brand.id}
                        value={brand.id}
                      >
                        {brand.title}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    component={TextField}
                    name="title"
                    label="Titre"
                    fullWidth
                  />
                  <Field
                    component={TextField}
                    name="key"
                    label="Trigramme"
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Annuler</Button>
                  <LoadingButton
                    data-cy="create-publication-save-button"
                    onClick={form.submit}
                    loading={isLoading}
                    color="primary"
                    variant="contained"
                  >
                    Sauvegarder
                  </LoadingButton>
                </DialogActions>
              </>
            );
          }}
        />
      </Dialog>
    </>
  );
};
