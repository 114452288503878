import { ApolloError } from '@apollo/client';

interface message {
  code: string;
  message: string;
}

export const handleApolloError = (
  error: ApolloError,
  messageStack: message[],
  defaultMessage = 'Une erreur à été rencontrée.',
): string => {
  if (error.graphQLErrors[0].extensions) {
    const catchedError = messageStack.find(
      (e) => e.code === error.graphQLErrors[0].extensions!.code,
    );
    if (catchedError) {
      return catchedError.message;
    }
  }
  return defaultMessage;
};
