import uuid from 'uuid/v4';
import {
  AssignmentCreationWithoutSubjectInput,
  AssignmentUpdateWithoutSubjectInput,
  GetRawArticleMajorVersions_rawArticle_majorVersions,
  GetSubject,
  GetSubject_subject_assignments_printHeading,
  MediaSearchStatus,
  RawArticleStatus,
  SubjectCreationInput,
  UpsertSubjectVariables,
} from '../../../__generated__/queries-topic';

export interface AssignmentFormModel {
  id: string | null;
  supportId: string | null;
  supportTitle: string;
  supportKey?: string;
  printIssueId: string;
  printIssueTitle: string;
  printHeadingId: string;
  printTemplateId: string;
  autoPrototype: boolean;
  printHeadingTitle: string;
  websiteTitle: string;
  mediaSearchStatus: MediaSearchStatus;
  rawArticle: {
    id: string;
    versions: {
      id: string;
      status: RawArticleStatus;
      title: string | null;
      editor?:
        | {
            name: string;
            id: string;
          }
        | null
        | undefined;
      createdAt?: string | undefined;
    }[];
  } | null;
  subject: {
    id: string;
  };
  needPortal?: boolean;
  isCreate?: boolean;
  versionSelected?: GetRawArticleMajorVersions_rawArticle_majorVersions;
  articleTemplate: {
    id: string;
  } | null;
  printHeading: GetSubject_subject_assignments_printHeading | null;
}

export const getEmptyAssignment = (subjectId: string): AssignmentFormModel => ({
  id: null,
  supportId: null,
  supportTitle: '',
  printIssueId: '',
  printHeadingId: '',
  printTemplateId: '',
  autoPrototype: false,
  printHeadingTitle: '',
  printIssueTitle: '',
  websiteTitle: '',
  mediaSearchStatus: MediaSearchStatus.Waiting,
  rawArticle: {
    id: '',
    versions: [],
  },
  subject: {
    id: subjectId,
  },
  articleTemplate: null,
  printHeading: null,
});
export interface SubjectFormModel {
  id: string;
  title: string;
  synopsis: string;
  mediaRequest: string;
  comment: string;
  creatorName: string;
  subjectCategory: string;
  subSubjectCategory: string;
  assignments: AssignmentFormModel[];
  unit: string;
  isCreate?: boolean;
  isClassified: boolean | null;
}

export const apiToForm = (
  queryData: GetSubject | { subject: null },
): SubjectFormModel => {
  if (!queryData.subject) {
    return {
      creatorName: '',
      id: '',
      title: '',
      synopsis: '',
      comment: '',
      subjectCategory: '',
      subSubjectCategory: '',
      mediaRequest: '',
      assignments: [],
      unit: '',
      isClassified: false,
    };
  }
  const { subject } = queryData;

  return {
    id: subject.id,
    title: subject.title,
    synopsis: subject.synopsis || '',
    mediaRequest: subject.mediaRequest || '',
    comment: subject.comment || '',
    creatorName: subject.createdBy ? subject.createdBy.name : '',
    assignments: subject.assignments.map((assignment) => ({
      mediaSearchStatus:
        (assignment.assignment && assignment.assignment.mediaSearchStatus) ||
        MediaSearchStatus.Waiting,
      id: (assignment.assignment && assignment.assignment.id) || '',
      supportId:
        (assignment.printPublication &&
          `print:${assignment.printPublication.id}`) ||
        (assignment.website &&
          `web:${assignment.website.id}:${assignment.website.key}`),
      supportTitle:
        (assignment.printPublication && assignment.printPublication.title) ||
        '',
      supportKey: assignment.website ? assignment.website.key : undefined,
      key: assignment.website ? assignment.website.key : undefined,
      websiteTitle: assignment.website ? assignment.website.title : '',
      printIssueId: (assignment.printIssue && assignment.printIssue.id) || '',
      printHeadingId:
        (assignment.printHeading && assignment.printHeading.id) || '',
      printTemplateId: assignment.assignment?.printTemplate?.id || '',
      autoPrototype:
        Boolean(assignment.printHeading?.autoPrototype) ||
        Boolean(assignment.assignment?.printTemplate?.id),
      printHeadingTitle:
        (assignment.printHeading && assignment.printHeading.title) || '',
      printIssueTitle:
        (assignment.printIssue && assignment.printIssue.title) || '',
      rawArticle: assignment.rawArticle || null,
      subject: { id: subject.id },
      articleTemplate: assignment.assignment?.articleTemplate || null,
      printHeading: assignment.printHeading || null,
    })),
    subjectCategory: subject.subjectCategory
      ? subject.subjectCategory.parent
        ? subject.subjectCategory.parent.id
        : subject.subjectCategory.id
      : '',
    subSubjectCategory:
      subject.subjectCategory && subject.subjectCategory.parent
        ? subject.subjectCategory.id
        : '',
    unit: subject.unit.id,
    isClassified: subject.isClassified,
  };
};

const getCreateAssignmentFromSubject = (): AssignmentCreationWithoutSubjectInput => ({
  printPublication: null,
  printIssue: null,
  printHeading: null,
  rawArticle: null,
  website: null,
});

export const getAssignmentConnections = (
  assignment: AssignmentFormModel,
  assignmentConnections:
    | AssignmentCreationWithoutSubjectInput
    | AssignmentUpdateWithoutSubjectInput,
  fromUpdate: boolean,
): AssignmentCreationWithoutSubjectInput => {
  if (
    assignment.supportId &&
    !assignment.printIssueId &&
    !assignment.printHeadingId
  ) {
    const [type, id] = assignment.supportId.split(':');
    if (type === 'print') {
      assignmentConnections.printPublication = {
        connect: { id },
      };
    } else if (type === 'web') {
      assignmentConnections.website = {
        connect: { id },
      };
    }
  }
  if (assignment.printIssueId && !assignment.printHeadingId) {
    assignmentConnections.printIssue = {
      connect: { id: assignment.printIssueId },
    };
  }
  if (assignment.printHeadingId) {
    assignmentConnections.printHeading = {
      connect: { id: assignment.printHeadingId },
    };
  }
  if (assignment.rawArticle && assignment.rawArticle.id) {
    assignmentConnections.rawArticle = {
      connect: { id: assignment.rawArticle.id },
    };
  }

  if (assignment.id && !fromUpdate) {
    (assignmentConnections as AssignmentCreationWithoutSubjectInput).id =
      assignment.id;
  }

  return assignmentConnections;
};

export const formToApi = (
  formData: SubjectFormModel,
): UpsertSubjectVariables => {
  const model: SubjectCreationInput = {
    title: formData.title,
    synopsis: formData.synopsis,
    comment: formData.comment || null,
    mediaRequest: formData.mediaRequest,
    unit: { connect: { id: formData.unit } },
    assignments: {
      create: formData.assignments
        .filter((assignment) => assignment.isCreate || !assignment.id)
        .map((assignment) => {
          const newRawArticleId = uuid();
          const newRawArticle = !assignment.versionSelected
            ? {}
            : {
                rawArticle: {
                  create: {
                    id: newRawArticleId,
                    versions: {
                      create: [
                        {
                          id: uuid(),
                          editorId: assignment.versionSelected.editorId,
                          title: assignment.versionSelected.title,
                          lead: assignment.versionSelected.lead,
                          body: assignment.versionSelected.body,
                          signature: assignment.versionSelected.signature,
                          status: assignment.versionSelected.status,
                          createdAt: assignment.versionSelected.createdAt,
                        },
                      ],
                    },
                  },
                },
              };

          return {
            mediaSearchStatus: assignment.mediaSearchStatus,
            ...getAssignmentConnections(
              assignment,
              getCreateAssignmentFromSubject(),
              false,
            ),
            ...newRawArticle,
          };
        }),
    },
    isClassified: formData.isClassified,
  };

  const subjectCategoryId =
    formData.subSubjectCategory || formData.subjectCategory;

  if (subjectCategoryId) {
    model.subjectCategory = {
      connect: {
        id: subjectCategoryId,
      },
    };
  }

  const id = formData.id ? formData.id : uuid();

  return {
    create: { id, ...model },
    update: { ...model },
    where: { id },
  };
};
