import { SearchPhotosPhoto } from 'apollo/queries/photos.photo.graphql';
import { MetadataNameEnumType } from '__generated__/queries-photo';

export const Metadata = {
  ...MetadataNameEnumType,
  ExportTypeWeb: MetadataNameEnumType.UserDefined239,
  ExportTypePrint: MetadataNameEnumType.UserDefined238,
  SubjectSlug: MetadataNameEnumType.CustomField16,
  UndersubjectSlug: MetadataNameEnumType.CustomField17,
} as const;

export const MetadataAsArray = {
  BackupName: Metadata.BackupName,
  Keywords: Metadata.subject,
  Creators: Metadata.creator,
  ProcessHistory: Metadata.ProcessHistory,
} as const;

export const getMetadata = (
  metadataName: MetadataNameEnumType,
  photo: Pick<SearchPhotosPhoto, 'metadataByName'> | undefined,
) => photo?.metadataByName?.[metadataName]?.[0]?.value;

export const getMetadataAsArray = (
  metadataName: MetadataNameEnumType,
  photo: Pick<SearchPhotosPhoto, 'metadataByName'> | undefined,
) => {
  if (!photo) return [];
  return (
    photo.metadataByName?.[metadataName]
      ?.filter((metadata) => !!metadata.value)
      .map((metadata) => metadata.value!) || []
  );
};

const metadataIsArray = (metadata: MetadataNameEnumType) =>
  Object.values(MetadataAsArray).includes(metadata as any);

export const extractMetadata = (
  metadata: MetadataNameEnumType,
  photo: Pick<SearchPhotosPhoto, 'metadataByName'> | undefined,
) =>
  metadataIsArray(metadata)
    ? getMetadataAsArray(metadata, photo)
    : getMetadata(metadata, photo);
