import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  LoadingButton,
  NotificationTypeEnum,
  pluralize,
  useNotification,
  MultipleValuesField,
  useDialog,
  objectsAreDifferents,
} from '@prismamedia/one-components';
import { InfosConfigType } from '../PhotoInfos/utils';
import React, { FC, useEffect, useState, useCallback, useMemo } from 'react';
import { useStyles } from './styles';
import {
  UpsertPhotoMetadataValue,
  useUpdatePhoto,
} from 'apollo/mutations/photos.photo.graphql';
import { MetadataNameEnumType } from '__generated__/queries-photo';
import { extractMetadata } from 'utils/getMetadata';
import { FieldsValues } from '../PhotoInfos';
import { infosConfig } from '../PhotoInfos/template';
import { SearchPhotosPhoto } from 'apollo/queries/photos.photo.graphql';

interface InfoEditDialogProps {
  selection: SearchPhotosPhoto[];
}

interface InfosConfigTypeWithMetadata extends InfosConfigType {
  metadata: MetadataNameEnumType;
}

export const InfoEditDialog: FC<InfoEditDialogProps> = ({ selection }) => {
  const classes = useStyles();
  const { closeDialog } = useDialog();
  const fields = useMemo(
    () =>
      infosConfig
        .filter((config) => config.editRenderer && config.metadata)
        .map((config) => config as InfosConfigTypeWithMetadata),
    [],
  );
  const editableFields = useMemo(() => fields.map(({ metadata }) => metadata), [
    fields,
  ]);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [value, setValue] = useState<FieldsValues>({});
  const [loading, setLoading] = useState(false);
  const [multipleValuesFields, setMultipleValuesFields] = useState<
    MetadataNameEnumType[]
  >([]);
  const fieldsHalfLength = Math.ceil(fields.length / 2);
  const updatePhoto = useUpdatePhoto();
  const { pushNotification } = useNotification();

  const fieldHasMultipleValues = useCallback(
    (field: MetadataNameEnumType) =>
      selection.some((photo) =>
        objectsAreDifferents(
          extractMetadata(field, photo),
          extractMetadata(field, selection[0]),
        ),
      ),
    [selection],
  );

  useEffect(() => {
    if (editableFields && selection.length) {
      setValue(
        editableFields.reduce(
          (prev, key) => ({
            ...prev,
            [key]: fieldHasMultipleValues(key)
              ? undefined
              : extractMetadata(key, selection[0]),
          }),
          {} as any,
        ),
      );
      setMultipleValuesFields(editableFields.filter(fieldHasMultipleValues));
    }
  }, [selection, editableFields, fieldHasMultipleValues]);

  const mapField = (infosConf: InfosConfigTypeWithMetadata[], startIndex = 0) =>
    infosConf.map((config, i) => (
      <MultipleValuesField
        key={`edit-field-${startIndex + i}`}
        className={classes.field}
        multipleValues={multipleValuesFields.includes(config.metadata)}
        label={config.title}
        onEditClick={() =>
          setMultipleValuesFields((prev) =>
            prev.filter((field) => field !== config.metadata),
          )
        }
        fieldRenderer={(fieldRef) =>
          config.editRenderer?.({
            inputRef: (el: any) => {
              fieldRef.current = el;
            },
            variant: 'outlined',
            title: config.title,
            value: value[config.metadata],
            onChange: (val) => {
              setValue((prev: any) => ({ ...prev, [config.metadata]: val }));
            },
          })
        }
      />
    ));

  const onSave = async () => {
    const metadatas: UpsertPhotoMetadataValue[] = Object.entries(value)
      .map(
        ([key, val]) => [key, val] as [MetadataNameEnumType, string | string[]],
      )
      .filter(([key]) => !multipleValuesFields.includes(key))
      .flatMap(([k, v]) =>
        (Array.isArray(v) ? v : [v]).map((val) => ({
          metadata: k,
          value: val,
        })),
      );

    setLoading(true);

    try {
      if (metadatas.length) {
        await Promise.all(
          selection.map((photo) => updatePhoto({ photo, metadatas })),
        );
      }
      closeDialog();
      pushNotification({
        message: 'Les informations ont été modifiées',
        type: NotificationTypeEnum.success,
      });
    } catch (e: any) {
      pushNotification({
        message: e.message,
        type: NotificationTypeEnum.error,
      });
    }

    setLoading(false);
  };

  return (
    <>
      <DialogTitle className={classes.title}>
        Modifier les informations
      </DialogTitle>
      <Typography color="primary" className={classes.subtitle}>
        {selection.length} {pluralize('image', selection.length)}{' '}
        {pluralize('sélectionnée', selection.length)}
      </Typography>
      <DialogContent className={classes.content}>
        <div className={classes.column}>
          {mapField(
            fields.slice(0, smallScreen ? fields.length : fieldsHalfLength),
          )}
        </div>
        {!smallScreen && (
          <div className={classes.column}>
            {mapField(
              fields.slice(fieldsHalfLength, fields.length),
              fieldsHalfLength,
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Annuler
        </Button>
        <LoadingButton
          loading={loading}
          color="primary"
          variant="contained"
          onClick={onSave}
        >
          Sauvegarder
        </LoadingButton>
      </DialogActions>
    </>
  );
};
