import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import BrightnessAutoIcon from '@mui/icons-material/BrightnessAuto';
import DescriptionIcon from '@mui/icons-material/Description';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PublicIcon from '@mui/icons-material/Public';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import React, { FC } from 'react';
import { RawArticleStatus } from '../../../__generated__/queries-topic';
import { paths } from '../../../routing';
import { getAssignmentTooltipTitle } from '../../../utils/assignment';
import { getColorByStatus } from '../../../utils/rawArticleStatus';
import { TopicLink, TopicLinkProps } from '../TopicLink';

interface Assignment {
  printTemplateId?: string;
  autoPrototype: boolean;
  isMultiSubject?: boolean;
  id: string | null;
  printHeadingId?: string;
  rawArticle: {
    id: string;
    versions: {
      id: string;
      status: RawArticleStatus;
      title: string | null;
      editor?: { name: string; id: string } | null;
      createdAt?: string;
    }[];
  } | null;
  website: {
    id: string;
    key: string;
  } | null;
}

interface ArticleLinkProps {
  assignment: Assignment;
  linkProps?: TopicLinkProps;
  tooltipProps?: Pick<TooltipProps, 'placement'>;
  subjectId: string;
}

export const ArticleLink: FC<ArticleLinkProps> = ({
  assignment: {
    id,
    printHeadingId,
    rawArticle,
    website,
    printTemplateId,
    autoPrototype,
    isMultiSubject,
  },
  linkProps,
  tooltipProps,
  children,
  subjectId,
}) => {
  const color = rawArticle?.versions[0]
    ? {
        color: getColorByStatus(rawArticle.versions[0].status),
      }
    : {};

  if (isMultiSubject) {
    return (
      <TopicLink
        to={paths.MULTI_SUBJECT_AUTO_EDIT}
        params={{
          printHeadingId: printHeadingId || '',
          originId: subjectId || '',
        }}
      >
        {children || (
          <Tooltip title="Accéder au Multi-sujets" placement="top-end">
            <DashboardIcon style={color} />
          </Tooltip>
        )}
      </TopicLink>
    );
  }

  if (website && rawArticle) {
    const url = `${config.FRONT_ONE_WEB}#/${website.key}/article/${
      rawArticle!.id
    }/version/${rawArticle!.versions[0]!.id}`;
    return (
      <Tooltip title="Accéder à l'article sur ONE WEB" {...tooltipProps}>
        <a href={url} target="_blank" rel="noreferrer">
          <PublicIcon />
        </a>
      </Tooltip>
    );
  }

  const editArticlePath = autoPrototype
    ? paths.ARTICLE_AUTO_EDIT
    : paths.ARTICLE_EDIT;
  const newArticlePath = autoPrototype
    ? paths.ARTICLE_AUTO_ADD
    : paths.ARTICLE_ADD;
  if (
    rawArticle?.id &&
    (!autoPrototype || (autoPrototype && printTemplateId))
  ) {
    if (children) {
      return (
        <TopicLink
          to={editArticlePath}
          params={{ id: rawArticle.id }}
          {...linkProps}
        >
          {children}
        </TopicLink>
      );
    }
    const tooltipTitle =
      getAssignmentTooltipTitle(rawArticle) || "Editer l'article";
    return (
      <TopicLink
        to={editArticlePath}
        params={{ id: rawArticle.id }}
        {...linkProps}
      >
        <Tooltip title={tooltipTitle} {...tooltipProps}>
          {autoPrototype ? (
            <BrightnessAutoIcon style={color} />
          ) : (
            <DescriptionIcon style={color} />
          )}
        </Tooltip>
      </TopicLink>
    );
  }

  if (id) {
    return (
      <TopicLink
        to={newArticlePath}
        params={{ assignmentId: id }}
        {...linkProps}
      >
        <Tooltip title="Créer l'article" {...tooltipProps}>
          <>
            {children ||
              (autoPrototype ? <BrightnessAutoIcon /> : <NoteAddIcon />)}
          </>
        </Tooltip>
      </TopicLink>
    );
  }
  return (
    <Tooltip title="Sauvegarder pour accéder à l'article" {...tooltipProps}>
      {(children as React.ReactElement) || <ReportProblemIcon />}
    </Tooltip>
  );
};
