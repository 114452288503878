import { Head } from 'components/Head';
import { FC } from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import { useGetAssignment } from '../../apollo/queries/assignment.topic.graphql';
import { useGetRawArticle } from '../../apollo/queries/rawArticle.topic.graphql';
import { AssignmentTitle } from '../../components/AssignmentTitle';
import { CircularLoader } from '../../components/Loader/Circular';
import { paths, replaceParams } from '../../routing';
import { ArticleForm } from './Form';
import { ArticleFormModel, apiToForm } from './Form/dataTransformer';

export const CreateArticle: FC = () => {
  const { assignmentId } = useParams<{ assignmentId: string }>();
  const { data, loading, error } = useGetAssignment({ assignmentId });
  const location = useLocation();

  if (loading) {
    return <CircularLoader />;
  }
  const assignment = data?.assignment;
  if (error || !assignment) {
    return <div>ERROR</div>;
  }
  if (assignment.printHeading?.autoPrototype) {
    return (
      <Redirect
        to={{
          pathname: replaceParams(paths.ARTICLE_AUTO_ADD, {
            assignmentId,
          }),
          search: location.search,
        }}
      />
    );
  }
  const initialValuesFromApi: ArticleFormModel = {
    ...apiToForm({ rawArticle: null }),
    assignmentId,
  };
  return (
    <ArticleForm
      initialValues={initialValuesFromApi}
      versions={[]}
      textIsReadOnly={initialValuesFromApi.textIsReadOnly}
      subjectTitle="Nouvel article"
      unitTitle={assignment.subject.unit.title}
    />
  );
};

export const EditArticle: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading, error, refetch } = useGetRawArticle({ id });
  const history = useHistory();
  const location = useLocation();

  if (loading) {
    return <CircularLoader />;
  }
  if (!data || !data.rawArticle || error) {
    return <div>ERROR</div>;
  }

  const { rawArticle } = data;
  if (rawArticle.assignment?.printHeading?.autoPrototype) {
    history.push({
      pathname: replaceParams(paths.ARTICLE_AUTO_EDIT, {
        id: rawArticle.id,
      }),
      search: location.search,
    });
    return <></>;
  }

  const initialValuesFromApi: ArticleFormModel = apiToForm(data);

  const subjectData: {
    subjectTitle: string;
    subjectId?: string;
    subjectSynopsis?: string;
    unit: { id: string; title: string };
  } = { subjectTitle: 'Sans titre', unit: { id: '', title: '' } };

  if (rawArticle && rawArticle.assignment) {
    subjectData.subjectId = rawArticle.assignment.subject.id;
    subjectData.subjectTitle = rawArticle.assignment.subject.title;
    subjectData.subjectSynopsis = rawArticle.assignment.subject.synopsis || '';
    if (rawArticle.assignment.subject.unit) {
      subjectData.unit.id = rawArticle.assignment.subject.unit.id;
      subjectData.unit.title = rawArticle.assignment.subject.unit.title;
    }
  }
  return (
    <>
      <Head title={subjectData.subjectTitle} />
      <ArticleForm
        rawArticleId={rawArticle.id}
        isReadyForMockup={!!rawArticle.assignment!.printHeading}
        initialValues={initialValuesFromApi}
        assignmentTitle={
          <AssignmentTitle
            assignment={rawArticle.assignment!}
            withLinkOnPrintIssue
          />
        }
        versions={rawArticle.versionList}
        textIsReadOnly={initialValuesFromApi.textIsReadOnly}
        refetch={refetch}
        unitId={subjectData.unit.id}
        unitTitle={subjectData.unit.title}
        {...subjectData}
      />
    </>
  );
};
