import { IconButton, Paper, Tooltip, Typography } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import { EditorState } from 'draft-js';
import debounce from 'lodash.debounce';
import React, { useContext, useEffect, useState } from 'react';
import { ArticleAutoContext } from '..';
import { getCountFromText } from '../../../components/CharCounter/utils';
import {
  CircularLoader,
  LoaderSizes,
} from '../../../components/Loader/Circular';
import { pluralize } from '../../../utils/addPlural';
import { PrintTemplateDraftFormatType } from '../../../__generated__/queries-topic';
import { GlobalCharCounter } from '../CharCounter/GlobalCharCounter';
import { serializeFormValues } from '../dataTransformer';
import { useGetTemplateDraft } from '../genTemplateDraft.topic.graphql';
import { useStyles } from './styles';

const selectedTextCounter = (text: string) => {
  const { wordCount, charCount } = getCountFromText(text);
  return (
    <Typography variant="body1">
      {`Total de la sélection: ${charCount} ${pluralize(
        'Caractère',
        'Caractères',
        charCount,
      )} - ${wordCount} ${pluralize('Mot', 'Mots', wordCount)}`}
    </Typography>
  );
};

export const ArticleHeader = () => {
  const classes = useStyles();
  const [isDownloadingDraft, setIsDownlaodingDraft] = useState(false);
  const [downloadAnchorEl, setDownloadAnchorEl] = useState();
  const genTemplateDraft = useGetTemplateDraft();
  const { pushNotification } = useNotification();
  const [selectedText, setSelectedText] = useState<string | null>('');
  const {
    formValues,
    selectedPrintTemplateId,
    togglePreview,
    showPreview,
    isPreviewLoading,
    previewResponseTime,
    assignmentData,
  } = useContext(ArticleAutoContext);

  const onSelectText = () => {
    const selection = window.getSelection();
    if (!selection || selection.isCollapsed) {
      setSelectedText(null);
      return;
    }
    setSelectedText(selection.toString());
  };

  const handleDownloadDraft = (
    format: PrintTemplateDraftFormatType,
  ) => async () => {
    setIsDownlaodingDraft(true);

    const fieldsData = serializeFormValues(
      selectedPrintTemplateId,
      formValues,
    ).fields.filter((field) => Boolean(field.value));

    const { errors, data } = await genTemplateDraft({
      printHeadingId: assignmentData?.printHeading?.id || '',
      printTemplateId: selectedPrintTemplateId,
      format,
      fieldsData,
      embedRessources: format === PrintTemplateDraftFormatType.INDD,
    });

    if (errors || !data) {
      pushNotification({
        message:
          'Un problème est survenu lors de la génération URL du téléchargement.',
        type: NotificationTypeEnum.error,
      });
      return;
    }

    const anchor = document.createElement('a');
    document.body.appendChild(anchor);

    data.printTemplateDrafts.artifacts.forEach((artifact, index) => {
      anchor.href = artifact.url;
      anchor.download = index.toString();
      anchor.click();
    });

    setTimeout(() => anchor.remove());

    setIsDownlaodingDraft(false);
    setDownloadAnchorEl(undefined);
  };

  useEffect(() => {
    const ref = debounce(onSelectText, 100);
    document.addEventListener('selectionchange', ref);
    return () => {
      document.removeEventListener('selectionchange', ref);
    };
  }, []);

  const getTextDraftInputValues = formValues
    .filter((field) => field.type !== 'IMAGE')
    .map((field) => field.value as string | EditorState);

  const handleMenuToggle = (evt: any) => {
    setDownloadAnchorEl((ref: any) => (ref ? undefined : evt.currentTarget));
  };

  return (
    <Paper square elevation={1} className={classes.paperWrapper}>
      <div
        data-cy="article-form-subject-header"
        className={classes.articleHeader}
      >
        <div className={classes.assignment}>
          {selectedText ? (
            selectedTextCounter(selectedText)
          ) : (
            <GlobalCharCounter values={getTextDraftInputValues} />
          )}
        </div>
      </div>
      <div className={classes.headerRightGroup}>
        <div>
          {isPreviewLoading && <CircularLoader size={LoaderSizes.xsmall} />}
        </div>
        {isPreviewLoading && ' Chargement en cours...'}
        {!isPreviewLoading && `Temps de réponse ${previewResponseTime}s`}
        <IconButton onClick={togglePreview} size="large">
          <Tooltip title={showPreview ? "Fermer l'aperçu" : 'Aperçu'}>
            {showPreview ? (
              <VisibilityIcon color="primary" />
            ) : (
              <VisibilityOffIcon />
            )}
          </Tooltip>
        </IconButton>
        <IconButton
          onClick={handleMenuToggle}
          disabled={isDownloadingDraft}
          size="large"
        >
          <Tooltip title="Télécharger">
            {isDownloadingDraft ? (
              <CircularLoader size={LoaderSizes.xsmall} />
            ) : (
              <CloudDownloadIcon color="primary" />
            )}
          </Tooltip>
        </IconButton>
        <Menu
          anchorEl={downloadAnchorEl}
          open={Boolean(downloadAnchorEl)}
          onClose={handleMenuToggle}
        >
          <MenuItem
            dense
            onClick={handleDownloadDraft(PrintTemplateDraftFormatType.PDF)}
          >
            <ListItemText primary="Télécharger fichier PDF" />
          </MenuItem>
          <MenuItem
            dense
            onClick={handleDownloadDraft(PrintTemplateDraftFormatType.INDD)}
          >
            <ListItemText primary="Télécharger fichier INDESIGN" />
          </MenuItem>
        </Menu>
      </div>
    </Paper>
  );
};
