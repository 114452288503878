import downloadFile from 'js-file-download';

export const getImageBlob = async (imageSrc: string) => {
  const image = await fetch(imageSrc);
  return await image.blob();
};

export const downloadImage = async (
  imageSrc: string,
  fileName?: string | null,
) => {
  const imageBlob = await getImageBlob(imageSrc);
  return downloadFile(imageBlob, fileName?.trim() || 'sans-titre.jpg');
};
