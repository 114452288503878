import { OneMedia } from '@prismamedia/one-components';
import { EditorState } from 'draft-js';
import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { ArticleAutoContext, FormField } from '..';

export const useConnectFieldToComponent = (
  field: FormField,
  setter:
    | Dispatch<SetStateAction<EditorState>>
    | Dispatch<SetStateAction<OneMedia[]>>,
) => {
  const { setFormStructure, formStructure } = useContext(ArticleAutoContext);
  useEffect(() => {
    if (formStructure) {
      const newFormStructure = [...formStructure];
      const matchField = newFormStructure.find(
        (target) => target.id === field.id,
      );
      if (matchField) {
        matchField.refresh = setter as any;
      }
      setFormStructure(newFormStructure);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setter]);
};
