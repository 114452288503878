import { ApolloQueryResult } from '@apollo/client';
import {
  CircularProgress,
  createTheme,
  Divider,
  IconButton,
  ThemeProvider,
  Paper,
} from '@mui/material';
import { common } from '@mui/material/colors';
import { Spellcheck } from '@mui/icons-material';
import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import { FormApi } from 'final-form';
import debounce from 'lodash.debounce';
import 'megadraft/dist/css/megadraft.css';
import { equals } from 'ramda';
import React, { createRef, FC, useEffect, useState } from 'react';
import { Field, FieldRenderProps, Form, FormSpy } from 'react-final-form';
import { useHistory, useLocation } from 'react-router-dom';
import {
  GetRawArticle,
  GetRawArticleVariables,
  RawArticleStatus,
  UpdateAssignment,
} from '../../../__generated__/queries-topic';
import { AppToolbar } from '../../../components/AppToolbar';
import { SaveButton } from '../../../components/Button/SaveButton';
import { DraftCharCounter } from '../../../components/CharCounter/Draft';
import { TextCharCounter } from '../../../components/CharCounter/Text';
import { FinalFormMaterialDraft } from '../../../components/FinalFormMaterial/Draft';
import { TextField } from '../../../components/FinalFormMaterial/TextField';
import { TopicNavigationPrompt } from '../../../components/NavigationPrompt';
import { PrintPortal } from '../../../components/PrintPortal';
import { InputText, ProlexisDialog } from '../../../components/ProlexisDialog';
import { paths, replaceParams } from '../../../routing';
import { auth } from '../../../utils/auth';
import { gaSendEvent } from '../../../utils/googleAnalytics';
import { getColorByStatus, Version } from '../../../utils/rawArticleStatus';
import { asideBlockPlugin } from '../AsideBlockPlugin';
import { AssignmentModal } from '../AssignmentModal';
import { MenuTop } from '../MenuTop';
import { ArticleTemplate } from '../PrintTemplate';
import { SubjectHeader } from '../SubjectHeader';
import { WorkflowConfirmStatusChangeModal } from '../WorkflowConfirmStatusChangeModal';
import { WorkflowDrawer } from '../WorkflowDrawer';
import { WorkflowStepper } from '../WorkflowStepper';
import { ArticleFormModel, formToApi } from './dataTransformer';
import { bodyDraftConfig, leadDraftConfig } from './megadraftActionsConfig';
import { useStyles } from './styles';
import { useAssignmentUpdate } from 'apollo/mutations/upsertAssignment.topic.graphql';

interface ArticleFormProps {
  initialValues: ArticleFormModel;
  subjectTitle: string;
  subjectSynopsis?: string;
  subjectId?: string;
  unitId?: string;
  unitTitle?: string;
  assignmentTitle?: JSX.Element | string;
  rawArticleId?: string;
  textIsReadOnly: boolean;
  isReadyForMockup?: boolean;
  refetch?: (
    variables?: GetRawArticleVariables,
  ) => Promise<ApolloQueryResult<GetRawArticle>>;
  versions: Version[];
}

interface ArticleFormState {
  isWorkflowDrawerOpen: boolean;
  isAssignmentModalOpen: boolean;
  isConfirmModalOpen: boolean;
  currentStatus: RawArticleStatus | null;
  nextStatus: RawArticleStatus | null;
  isNavigationPromptDisable: boolean;
  prolexisOpen: boolean;
  zoomValue: number;
}

export const ArticleForm: FC<ArticleFormProps> = ({
  initialValues: initialValuesFromApi,
  isReadyForMockup,
  subjectTitle,
  refetch,
  unitId,
  unitTitle,
  assignmentTitle,
  versions,
  textIsReadOnly,
  rawArticleId,
  subjectId,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const paperRef = createRef<HTMLDivElement>();
  const wrapperPaperRef = createRef<HTMLDivElement>();
  const [state, setState] = useState<ArticleFormState>({
    isWorkflowDrawerOpen: false,
    isAssignmentModalOpen: false,
    isConfirmModalOpen: false,
    nextStatus: null,
    currentStatus: initialValuesFromApi.status,
    isNavigationPromptDisable: false,
    prolexisOpen: false,
    zoomValue: 1,
  });
  const { pushNotification } = useNotification();

  const [updateAssignment, { loading }] = useAssignmentUpdate({
    onCompleted: async (result: UpdateAssignment) => {
      if (result.updateAssignment?.rawArticle?.id) {
        if (!rawArticleId) {
          setState((prev) => ({
            ...prev,
            isNavigationPromptDisable: true,
          }));
          history.push({
            pathname: replaceParams(paths.ARTICLE_EDIT, {
              id: result.updateAssignment?.rawArticle?.id,
            }),
            search: location.search,
          });
        }
        pushNotification({
          message: `Votre article a bien été sauvegardé`,
          type: NotificationTypeEnum.success,
        });
      }
    },
    onError: (error) => {
      console.error('error', error);
      pushNotification({
        message: "Erreur lors de l'enregistrement de l'article",
        type: NotificationTypeEnum.error,
      });
    },
  });

  const paperStyle = {
    '--zoom-value': state.zoomValue,
  } as React.CSSProperties;

  const openDrawer = () => {
    setState((prev) => ({
      ...prev,
      isWorkflowDrawerOpen: true,
    }));
  };

  const closeDrawer = () => {
    setState((prev) => ({
      ...prev,
      isWorkflowDrawerOpen: false,
    }));
  };

  const onClick = () => {
    openProlexis();
    gaSendEvent('Article', 'click', 'Prolexis');
  };

  const openProlexis = () => {
    setState((prev) => ({
      ...prev,
      prolexisOpen: true,
    }));
  };

  const closeProlexis = (form: FormApi) => (texts: InputText[] | false) => {
    setState((prev) => ({
      ...prev,
      prolexisOpen: false,
    }));
    if (texts !== false) {
      texts.forEach(({ name, value }) => form.change(name, value));
    }
  };

  const toggleAssignmentModal = () => {
    setState((prev) => ({
      ...prev,
      isAssignmentModalOpen: !prev.isAssignmentModalOpen,
    }));
  };

  const toggleConfirmModal = () => {
    setState((prev) => ({
      ...prev,
      isConfirmModalOpen: !prev.isConfirmModalOpen,
    }));
  };

  const onCmdS = (e: KeyboardEvent) => {
    if (e.key === 's' && e.metaKey) {
      // https://github.com/final-form/react-final-form/blob/master/docs/faq.md#via-documentgetelementbyid
      document
        .getElementById('article-form')!
        .dispatchEvent(new Event('submit', { bubbles: true }));
      e.preventDefault();
    }
    if (e.key === 'v' && e.metaKey) {
      setTimeout(() => handleResize(), 100);
    }
  };

  const handleResize = () => {
    const wrapper: HTMLDivElement | null = wrapperPaperRef.current;
    const paper: HTMLDivElement | null = paperRef.current;
    if (!wrapper || !paper) {
      return;
    }
    const { zoomValue } = state;
    const { width: paperWidth, height } = paper.getBoundingClientRect();
    const { width: wrapperWidth } = wrapper.getBoundingClientRect();
    wrapper.style.height = `${height * zoomValue + 150}px`;
    paper.style.left = `${Math.abs(
      (wrapperWidth / zoomValue - paperWidth) * 0.5,
    )}px`;
  };

  const updateStatusAndOpenConfirmModal = (
    nextStatus: RawArticleStatus,
    currentStatus: RawArticleStatus = state.currentStatus!,
  ) => {
    setState((prev) => ({
      ...prev,
      currentStatus,
      nextStatus,
    }));
    toggleConfirmModal();
  };

  const hasFormChangeSinceLastSubmit = (
    initialValues: ArticleFormModel,
    values: ArticleFormModel,
  ) => {
    if (state.isNavigationPromptDisable) {
      return false;
    }
    const cleanInitialValues = {
        title: initialValues.title,
        lead: initialValues.lead,
        body: initialValues.body,
        signature: initialValues.signature,
      },
      cleanValues = {
        title: values.title,
        lead: values.lead,
        body: values.body,
        signature: values.signature,
      };

    return !equals(cleanInitialValues, cleanValues);
  };

  const zoomArticle = (zoomValue: number) => {
    setState((prev) => ({
      ...prev,
      zoomValue: zoomValue * 0.01,
    }));
  };

  const createNewVersionWithStatus = (form: FormApi) => () => {
    if (!isReadyForMockup && state.nextStatus === RawArticleStatus.Mockup) {
      toggleAssignmentModal();
      return;
    }
    form.change('status', state.nextStatus);
    setState((prev) => ({
      ...prev,
      currentStatus: prev.nextStatus,
    }));
    setImmediate(() => form.submit());
  };

  useEffect(() => {
    setTimeout(() => handleResize(), 100);
    window.addEventListener('keydown', onCmdS);
    window.addEventListener('resize', debounce(handleResize, 100));
    return () => {
      window.removeEventListener('keydown', onCmdS);
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <Form
      initialValues={initialValuesFromApi}
      subscription={{}}
      onSubmit={
        ((values: ArticleFormModel) => {
          if (loading || !auth.user) {
            return;
          }

          const rawArticleDetails = formToApi(values, auth.user.id);

          updateAssignment({
            variables: {
              where: {
                id: values.assignmentId,
              },
              data: {
                rawArticle: !values.id
                  ? { create: rawArticleDetails.create }
                  : {
                      update: {
                        ...rawArticleDetails.update,
                      },
                    },
              },
            },
          });
        }) as any
      }
      render={({ form }) => {
        const statusColor = getColorByStatus(state.currentStatus!);
        const theme = createTheme({
          palette: {
            primary: {
              main: statusColor,
              contrastText: common.white,
            },
          },
        });

        const { isWorkflowDrawerOpen, prolexisOpen } = state;

        return (
          <form
            id="article-form"
            className={classes.form}
            onSubmit={(e) => {
              e.preventDefault();
              form.submit();
            }}
          >
            <ThemeProvider theme={theme}>
              <FormSpy subscription={{ values: true }}>
                {({ values }: { values: ArticleFormModel }) => (
                  <>
                    <AppToolbar
                      childrenJustifyContent="flex-end"
                      childrenClass={classes.appToolbarChildren}
                      title={`Sujet: ${subjectTitle}`}
                      editedAt={initialValuesFromApi.createdAt}
                      previousRoute={
                        subjectId
                          ? {
                              pathname: replaceParams(paths.SUBJECT_EDIT, {
                                id: subjectId,
                              }),
                            }
                          : { pathname: paths.SUBJECT_LIST }
                      }
                      assignmentTitle={assignmentTitle}
                      actionButton={
                        <>
                          <IconButton
                            color="inherit"
                            className={classes.prolexisButton}
                            onClick={onClick}
                            disabled={textIsReadOnly}
                            size="large"
                          >
                            <Spellcheck />
                          </IconButton>
                          <SaveButton
                            onClick={form.submit}
                            className={classes.saveButton}
                          />
                          {subjectId && rawArticleId && (
                            <MenuTop
                              updateStatus={updateStatusAndOpenConfirmModal}
                              subjectId={subjectId}
                              rawArticleId={rawArticleId}
                            />
                          )}
                          {prolexisOpen && (
                            <ProlexisDialog
                              unitTitle={unitTitle}
                              texts={[
                                {
                                  label: 'Corps',
                                  name: 'body',
                                  type: 'DRAFT',
                                  value: values.body,
                                },
                                {
                                  label: 'Titre',
                                  name: 'title',
                                  type: 'TEXT',
                                  value: values.title,
                                },
                                {
                                  label: 'Chapô',
                                  name: 'lead',
                                  type: 'DRAFT',
                                  value: values.lead,
                                },
                              ]}
                              handleClose={closeProlexis(form)}
                            />
                          )}
                        </>
                      }
                    >
                      <TopicNavigationPrompt
                        when={() =>
                          hasFormChangeSinceLastSubmit(
                            initialValuesFromApi,
                            values as ArticleFormModel,
                          )
                        }
                        title="Êtes-vous sur de vouloir quitter la page ?"
                        content="Vous avez des modifications non sauvegardées"
                        confirmLabel="Oui"
                        cancelLabel="Non"
                      />
                      {rawArticleId && (
                        <WorkflowStepper
                          userName={values.editor && values.editor.name}
                          avatarUrl={values.editor && values.editor.avatarUrl}
                          status={values.status}
                          updateStatus={updateStatusAndOpenConfirmModal}
                          allowedStatuses={values.allowedStatuses}
                          onStatusClick={openDrawer}
                        />
                      )}
                    </AppToolbar>
                    {loading && (
                      <div className={classes.loader}>
                        <CircularProgress />
                      </div>
                    )}
                    {rawArticleId && (
                      <WorkflowDrawer
                        rawArticleId={rawArticleId}
                        open={isWorkflowDrawerOpen}
                        onClose={closeDrawer}
                        currentStatus={values.status}
                        allowedStatuses={values.allowedStatuses}
                        versions={versions}
                        updateStatus={updateStatusAndOpenConfirmModal}
                      />
                    )}
                  </>
                )}
              </FormSpy>
              <div className={classes.main}>
                <SubjectHeader
                  zoomArticle={zoomArticle}
                  zoomValue={state.zoomValue}
                />
                <div className={classes.paperWrapper} ref={wrapperPaperRef}>
                  <Paper
                    ref={paperRef}
                    className={classes.paper}
                    style={paperStyle}
                    elevation={7}
                  >
                    <div className={classes.input}>
                      <Field
                        name="title"
                        label="Titre de l'article"
                        component={TextField}
                        disabled={textIsReadOnly}
                        fullWidth
                        multiline
                        InputProps={{ style: { fontSize: 24 } }}
                      />
                    </div>
                    <div className={classes.charCounter}>
                      <TextCharCounter name="title" />
                    </div>
                    <div
                      className={`${classes.input} ${
                        textIsReadOnly && classes.megadraftDisabled
                      }`}
                    >
                      <Field
                        component={
                          FinalFormMaterialDraft as FC<FieldRenderProps>
                        }
                        data-cy="article-form-chapo-draft-input"
                        sidebarRendererFn={() => <span />}
                        readOnly={textIsReadOnly}
                        placeholder="Contenu du chapô"
                        spellCheck={true}
                        actions={leadDraftConfig}
                        name="lead"
                        label="Contenu du chapô"
                      />
                    </div>
                    <Divider className={classes.divider} />
                    <div className={classes.charCounter}>
                      <DraftCharCounter name="lead" />
                    </div>
                    <div
                      className={`${classes.input} ${
                        textIsReadOnly && classes.megadraftDisabled
                      }`}
                    >
                      <Field
                        component={
                          FinalFormMaterialDraft as FC<FieldRenderProps>
                        }
                        name="body"
                        readOnly={textIsReadOnly}
                        placeholder="Contenu de l'article"
                        label="Contenu de l'article"
                        plugins={[asideBlockPlugin]}
                        actions={bodyDraftConfig}
                        spellCheck={true}
                        resize={handleResize}
                      />
                    </div>
                    <Divider className={classes.divider} />
                    <div className={classes.charCounter}>
                      <DraftCharCounter name="body" />
                    </div>
                    <div className={classes.inputSignature}>
                      <Field
                        name="signature"
                        label="Signature"
                        disabled={textIsReadOnly}
                        component={TextField}
                        fullWidth
                        multiline
                      />
                    </div>
                  </Paper>
                </div>
              </div>
              <PrintPortal>
                <FormSpy subscription={{ values: true }}>
                  {({ values }: { values: ArticleFormModel }) => (
                    <ArticleTemplate
                      title={values.title}
                      lead={values.lead}
                      body={values.body}
                      signature={values.signature}
                      subjectTitle={subjectTitle}
                      assignmentTitle={assignmentTitle || ''}
                      createdAt={values.createdAt}
                    />
                  )}
                </FormSpy>
              </PrintPortal>
              <WorkflowConfirmStatusChangeModal
                open={state.isConfirmModalOpen}
                createNewVersion={createNewVersionWithStatus(form)}
                toggleConfirmModal={toggleConfirmModal}
                currentStatus={state.currentStatus!}
                nextStatus={state.nextStatus!}
              />
              {rawArticleId && unitId && (
                <AssignmentModal
                  unitId={unitId}
                  toggleAssignmentModal={toggleAssignmentModal}
                  open={state.isAssignmentModalOpen}
                  refetch={refetch}
                  rawArticleId={rawArticleId}
                  createNewVersion={createNewVersionWithStatus(form)}
                />
              )}
            </ThemeProvider>
          </form>
        );
      }}
    />
  );
};
