import { Grid, Paper, Typography } from '@mui/material';
import Add from '@mui/icons-material/Add';
import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import classNames from 'classnames';
import { AnyObject, FormApi } from 'final-form';
import React, { FC, useState } from 'react';
import { AssignmentDialog } from '../../../components/Assignment/AssignmentDialog';
import { AssignmentItem } from '../../../components/Assignment/AssignmentItem';
import { DuplicateDialog } from '../DuplicateDialog';
import {
  AssignmentFormModel,
  getAssignmentConnections,
  getEmptyAssignment,
} from '../Form/dataTransformer';
import { useStyles } from './styles';
import { useAssignmentUpdate } from 'apollo/mutations/upsertAssignment.topic.graphql';

interface AssignmentsProps {
  subjectId: string;
  unitId: string;
  assignments: AssignmentFormModel[];
  refetchSubject?: () => void;
  form: FormApi;
  values: AnyObject;
  formErrors: {
    title?: string;
    subjectCategory?: string;
    subSubjectCategory?: string;
  };
}

interface AssignmentsState {
  assignmentIndex: number;
  dialogStep: number;
  openAssignmentDialog: boolean;
  openDuplicateDialog: boolean;
  rawArticleId: string;
  assignmentOriginIndex: number;
}

export const Assignments: FC<AssignmentsProps> = ({
  assignments,
  refetchSubject,
  subjectId,
  unitId,
  values,
  form,
  formErrors,
}) => {
  const classes = useStyles();
  const { pushNotification } = useNotification();
  const [state, setState] = useState<AssignmentsState>({
    assignmentIndex: -1,
    dialogStep: 0,
    openAssignmentDialog: false,
    openDuplicateDialog: false,
    rawArticleId: '',
    assignmentOriginIndex: -1,
  });

  const removeLastFormAssignment = () => {
    const currentAssignments = values.assignments;
    if (
      state.assignmentIndex !== -1 &&
      !values.assignments[state.assignmentIndex].id
    ) {
      currentAssignments.pop();
      form.change('assignments', currentAssignments);
    }
  };

  const handleOpenDialogAssignment = (assignmentIndex: number) => () => {
    setState((prev) => ({
      ...prev,
      assignmentIndex,
      openAssignmentDialog: true,
    }));
  };

  const handleNewAssignment = () => {
    if (!formIsValid()) {
      return;
    }
    const currentAssignments = values.assignments;
    const newAssignment = getEmptyAssignment(subjectId);
    form.change('assignments', [
      ...currentAssignments,
      {
        ...newAssignment,
      },
    ]);
    setState((prev) => ({
      ...prev,
      assignmentIndex: currentAssignments.length,
      openAssignmentDialog: !state.openDuplicateDialog,
    }));
  };

  const handleCloseDialogAssignment = () => {
    removeLastFormAssignment();
    form.reset();
    setState((prev) => ({
      ...prev,
      assignmentIndex: -1,
      dialogStep: 0,
      openAssignmentDialog: false,
    }));
  };

  const [updateAssignment] = useAssignmentUpdate({
    onCompleted: async (result) => {
      if (result.updateAssignment?.id) {
        pushNotification({
          message: 'Le sujet a bien été sauvegardé',
          type: NotificationTypeEnum.success,
        });
      }
    },
    refetchQueries: ['GetPrintHeadingById', 'GetSubject'],
  });

  const saveAssignment = async () => {
    // If we create a new assignment switch dialog to the next step in order
    // to suggest to user to write his article otherwise close the dialog
    if (!values.assignments[state.assignmentIndex].id) {
      form.submit();
      setState((prev) => ({
        ...prev,
        dialogStep: 1,
      }));
    } else {
      await updateAssignment({
        variables: {
          where: { id: values.assignments[state.assignmentIndex].id },
          data: getAssignmentConnections(
            values.assignments[state.assignmentIndex],
            {},
            true,
          ),
        },
      });
      setState((prev) => ({
        ...prev,
        openAssignmentDialog: false,
      }));
    }
  };

  const handleOpenDuplicateDialog = (assignmentIndex: number) => (
    rawArticleId: string,
  ) => () => {
    setState((prev) => ({
      ...prev,
      openDuplicateDialog: true,
      rawArticleId,
      assignmentOriginIndex: assignmentIndex,
    }));
  };

  const handleCloseDuplicateDialog = () => {
    removeLastFormAssignment();
    setState((prev) => ({
      ...prev,
      openDuplicateDialog: false,
      rawArticleId: '',
      assignmentIndex: -1,
    }));
  };

  const notificateSuccess = () => {
    const [lastAssignment] = assignments.slice(-1);
    const label: string =
      lastAssignment.supportId && lastAssignment.supportKey
        ? `WEB - ${lastAssignment.websiteTitle}`
        : `${lastAssignment.supportTitle} • 
      ${lastAssignment.printIssueTitle} • 
      ${lastAssignment.printHeadingTitle}`;

    pushNotification({
      message: `Votre article a été dupliqué dans la parution ${label}`,
      type: NotificationTypeEnum.success,
    });

    setState((prev) => ({
      ...prev,
      openDuplicateDialog: false,
      rawArticleId: '',
      assignmentIndex: -1,
    }));
  };

  const excludedIssues = (): string[] => {
    const { supportId, printIssueId } =
      assignments[state.assignmentIndex] || {};
    if (supportId && supportId.startsWith('print:')) {
      const [id] = supportId.split(':');
      return assignments
        .filter(
          (assignment: AssignmentFormModel) =>
            assignment.supportId &&
            assignment.supportId.startsWith('print:') &&
            assignment.supportId.split(':')[1] === id &&
            assignment.printIssueId !== '' &&
            assignment.printIssueId !== printIssueId,
        )
        .map((assignment: AssignmentFormModel) => assignment.printIssueId);
    }
    return [];
  };

  const formIsValid = () => !Object.keys(formErrors).length;

  return (
    <Paper className={classes.assignmentBox} elevation={0}>
      <Typography variant="h6" color="textSecondary" className={classes.title}>
        <strong>Affectations</strong>
      </Typography>
      <Grid container spacing={1}>
        {assignments.map((assignment, i) => (
          <Grid key={i} data-cy="assignment-grid" item xs={12}>
            <AssignmentItem
              assignment={assignment}
              subjectId={subjectId}
              refetchSubject={refetchSubject}
              handleDialogAssignment={handleOpenDialogAssignment(i)}
              handleDuplicateDialog={handleOpenDuplicateDialog(i)}
            />
          </Grid>
        ))}
      </Grid>
      {state.openAssignmentDialog && (
        <AssignmentDialog
          handleNewAssignment={handleNewAssignment}
          closeDialog={handleCloseDialogAssignment}
          assignment={assignments[state.assignmentIndex]}
          unitId={unitId}
          assignmentIndex={state.assignmentIndex}
          dialogStep={state.dialogStep}
          saveAssignment={saveAssignment}
          excludedIssues={excludedIssues()}
        />
      )}
      <div
        data-cy="subject-form-add-assignement"
        title={
          formIsValid()
            ? undefined
            : "Merci de renseigner au préalable l'intitulé et la thématique"
        }
        className={classNames(classes.addSupport, {
          disabled: !formIsValid(),
        })}
        onClick={handleNewAssignment}
      >
        <Add />
        <Typography color="inherit" variant="subtitle1">
          Ajouter un autre support
        </Typography>
      </div>
      {state.openDuplicateDialog && (
        <DuplicateDialog
          refetchSubject={refetchSubject}
          subjectId={subjectId}
          rawArticleId={state.rawArticleId}
          assignmentOriginIndex={state.assignmentOriginIndex}
          closeDialog={handleCloseDuplicateDialog}
          assignments={assignments}
          handleNewAssignment={handleNewAssignment}
          newAssignmentIndex={state.assignmentIndex}
          notificateSuccess={notificateSuccess}
        />
      )}
    </Paper>
  );
};
