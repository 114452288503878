import { ApolloQueryResult } from '@apollo/client';
import { Badge } from '@mui/material';
import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import clx from 'classnames';
import debounce from 'lodash.debounce';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { ArticleAutoContext, FormField } from '../';
import {
  GetPrintTemplateDraft,
  PrintTemplateDraftFormatType,
} from '../../../__generated__/queries-topic';
import { serializeFormValues } from '../dataTransformer';
import { useGetTemplateDraft } from '../genTemplateDraft.topic.graphql';
import { useStyles } from './styles';

export const DrafPreview: React.FC = () => {
  const classes = useStyles();
  const abortController = React.useRef<AbortController>();
  const genTemplateDraft = useGetTemplateDraft();
  const { pushNotification } = useNotification();

  const [previewResults, setPreviewResult] = useState<
    ApolloQueryResult<GetPrintTemplateDraft>
  >();

  const [artifactUrls, setArtifactUrls] = useState<{ [key: string]: string }>(
    {},
  );

  const {
    showPreview,
    selectedPrintTemplateId,
    formValues,
    setPreviewResponseTime,
    setIsPreviewLoading,
    isPreviewLoading,
    assignmentData,
  } = useContext(ArticleAutoContext);

  const updatePreview = useRef(
    debounce(
      async (
        printTemplateId: string,
        printHeadingId: string,
        fieldsValues: FormField[],
        pages: number[],
      ) => {
        setIsPreviewLoading(true);

        try {
          const controller = new window.AbortController();
          abortController.current = controller;

          const fieldsData = serializeFormValues(
            printTemplateId,
            fieldsValues,
          ).fields.filter((field) => Boolean(field.value));

          const startTime = performance.now();

          const result = await genTemplateDraft(
            {
              printHeadingId,
              printTemplateId,
              format: PrintTemplateDraftFormatType.PNG,
              pages,
              fieldsData,
            },
            {
              context: {
                fetchOptions: { signal: controller.signal },
                queryDeduplication: false,
              },
            },
          );

          setPreviewResponseTime(
            ((performance.now() - startTime) / 1000).toFixed(3),
          );

          if (result.data) {
            setArtifactUrls((peviousArtifacts) => ({
              ...peviousArtifacts,
              ...result.data!.printTemplateDrafts.artifacts.reduce(
                (urls, artifact) => {
                  return { ...urls, [artifact.page.toString()]: artifact.url };
                },
                {},
              ),
            }));
          }

          if (result.error) {
            throw result.error;
          }

          setPreviewResult(result);
        } catch (err) {
          pushNotification({
            message: (err as { message: string }).message,
            type: NotificationTypeEnum.error,
          });
        }

        setIsPreviewLoading(false);
      },
      1500,
    ),
  );

  const abortLatestPreviewRequest = () => {
    abortController.current && abortController.current.abort();
  };

  useEffect(() => {
    if (
      showPreview &&
      selectedPrintTemplateId &&
      assignmentData?.printHeading?.id
    ) {
      abortLatestPreviewRequest();
      updatePreview.current(
        selectedPrintTemplateId,
        assignmentData?.printHeading?.id,
        formValues,
        [],
      );
    }
  }, [
    selectedPrintTemplateId,
    formValues,
    showPreview,
    assignmentData?.printHeading?.id,
  ]);

  if (!previewResults) {
    return null;
  }

  if (previewResults.error) {
    return <div>{JSON.stringify(previewResults.error)}</div>;
  }

  return (
    <TransformWrapper
      doubleClick={{ step: 30 }}
      pinch={{ disabled: true }}
      pan={{ disabled: false }}
      wheel={{ disabled: false, step: Object.keys(artifactUrls).length / 2 }}
      options={{
        centerContent: false,
        minScale: 0.5,
        limitToBounds: false,
        ...{ wrapperClass: classes.fullWidth },
      }}
      defaultScale={0.9}
    >
      <TransformComponent>
        <div className={classes.draftPreviewContainer}>
          {Object.keys(artifactUrls).map((page, index) => (
            <div className={classes.draftImgContainer} key={index}>
              <Badge
                key={page}
                badgeContent={index + 1}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                classes={{
                  root: classes.draftPreviewBadge,
                }}
              />
              <img
                alt=""
                src={artifactUrls[page]}
                className={clx(
                  classes.draftPreviewImg,
                  isPreviewLoading && classes.draftPreviewImgLoading,
                )}
              />
            </div>
          ))}
        </div>
      </TransformComponent>
    </TransformWrapper>
  );
};
