import { DataProxy, useApolloClient } from '@apollo/client';
import { DialogContent, DialogTitle } from '@mui/material';
import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import User, { RawUser } from '@prismamedia/one-user';
import { difference } from 'ramda';
import React, { FC } from 'react';
import {
  DeleteSubjectUserVariables,
  GetSubject_subject_subjectUsers,
  GetSubjectUsers,
  ShareSubjectVariables,
} from '../../../__generated__/queries-topic';
import { ShareForm, ShareFormModel } from '../ShareForm';
import { useSubjectUserDeleter } from './deleteSubjectUser.topic.graphql';
import {
  GET_SUBJECT_USERS,
  useGetSubjectUsers,
} from './getSubjectUsers.topic.graphql';
import { useSubjectSharer } from './shareSubjectMutation.topic.graphql';

interface ShareSubjectDialogProps {
  subjectId: string;
  closeDialog: () => void;
}

const doMutations = (
  userIdsToAdd: string[],
  userIdsToRemove: string[],
  subjectId: string,
  shareMutate: (params: { variables: ShareSubjectVariables }) => void,
  deleteUserMutate: (params: { variables: DeleteSubjectUserVariables }) => void,
): Promise<void[]> => {
  const deletePromises = [
    ...userIdsToRemove.map((userId) =>
      deleteUserMutate({
        variables: { subjectId, userId },
      }),
    ),
  ];

  if (userIdsToAdd.length === 0) {
    return Promise.all(deletePromises);
  }

  return Promise.all([
    ...deletePromises,
    shareMutate({
      variables: {
        subject: subjectId,
        userIds: userIdsToAdd,
      },
    }),
  ]);
};

const mapUserToSubjectUser = (
  users: User[],
): GetSubject_subject_subjectUsers[] =>
  users.map((user) => ({
    userId: user.id,
    __typename: 'SubjectUser',
    user: {
      __typename: 'User',
      email: user.email,
      avatarUrl: user.avatarUrl || '',
      name: user.name,
    },
  })) as GetSubject_subject_subjectUsers[];

const getSubjectUsersFromUserIds = (
  userIds: string[],
  users: RawUser[],
): GetSubject_subject_subjectUsers[] => {
  const usersToAdd = userIds.map((id) =>
    users.find((user) => user.id === id),
  ) as User[];
  return mapUserToSubjectUser(usersToAdd);
};

const updateCallback = async (
  cache: DataProxy,
  userIdsToAdd: string[],
  userIdsToRemove: string[],
  selectedUsers: RawUser[],
  subjectId: string,
): Promise<void> => {
  const getSubjectUsersQuery: GetSubjectUsers | null = cache.readQuery({
    query: GET_SUBJECT_USERS,
    variables: {
      id: subjectId,
    },
  });

  if (!getSubjectUsersQuery || !getSubjectUsersQuery.subject) {
    return;
  }

  const {
    subjectUsers: subjectUsersInCache = [],
  } = getSubjectUsersQuery.subject;

  const newSubjectUsers = [
    ...subjectUsersInCache.filter(
      ({ userId }) => !userIdsToRemove.includes(userId),
    ),
    ...getSubjectUsersFromUserIds(userIdsToAdd, selectedUsers),
  ];

  return cache.writeQuery({
    query: GET_SUBJECT_USERS,
    variables: {
      id: subjectId,
    },
    data: {
      ...getSubjectUsersQuery,
      subject: {
        ...getSubjectUsersQuery.subject,
        subjectUsers: newSubjectUsers,
      },
    },
  });
};

export const ShareSubjectDialog: FC<ShareSubjectDialogProps> = ({
  subjectId,
  closeDialog,
}) => {
  const { pushNotification } = useNotification();
  const client = useApolloClient();
  const { data: subjectData, loading, error } = useGetSubjectUsers({
    id: subjectId,
  });
  const [deleteSubjectUserMutate] = useSubjectUserDeleter({
    onCompleted: () => {
      pushNotification({
        message: "Droits d'accès mis à jour.",
        type: NotificationTypeEnum.success,
      });
    },
    onError: () => {
      pushNotification({
        message: 'Une erreur est survenue.',
        type: NotificationTypeEnum.error,
      });
    },
  });
  const [shareSubject] = useSubjectSharer({
    onCompleted: () => {
      pushNotification({
        message: "Droits d'accès mis à jour.",
        type: NotificationTypeEnum.success,
      });
      closeDialog();
    },
    onError: () => {
      pushNotification({
        message: 'Une erreur est survenue.',
        type: NotificationTypeEnum.error,
      });
    },
  });
  const handleSubmit = async (
    { selectedUsers }: ShareFormModel,
    subjectUsers: GetSubject_subject_subjectUsers[],
    shareMutate: (params: { variables: ShareSubjectVariables }) => void,
    deleteUserMutate: (params: {
      variables: DeleteSubjectUserVariables;
    }) => void,
  ): Promise<void> => {
    const userIdsToRemove = difference(
      subjectUsers.map((su) => su.userId),
      selectedUsers.map((u) => u.id),
    );

    const userIdsToAdd = difference(
      selectedUsers.map((u) => u.id),
      subjectUsers.map((su) => su.userId),
    );

    if (userIdsToRemove.length + userIdsToAdd.length === 0) {
      return;
    }

    await doMutations(
      userIdsToAdd,
      userIdsToRemove,
      subjectId,
      shareMutate,
      deleteUserMutate,
    );
    return updateCallback(
      client,
      userIdsToAdd,
      userIdsToRemove,
      selectedUsers,
      subjectId,
    );
  };

  return (
    <>
      <DialogTitle>Partager un sujet</DialogTitle>
      {loading ? (
        <DialogContent>Chargement en cours.</DialogContent>
      ) : !subjectData ||
        !subjectData.subject ||
        !Array.isArray(subjectData.subject.subjectUsers) ||
        error ? (
        <DialogContent>Une erreur est survenue.</DialogContent>
      ) : (
        <ShareForm
          onCancel={closeDialog}
          onSubmit={(values) =>
            handleSubmit(
              values,
              subjectData.subject!.subjectUsers,
              shareSubject,
              deleteSubjectUserMutate,
            )
          }
          subjectUsers={subjectData.subject!.subjectUsers}
        />
      )}
    </>
  );
};
